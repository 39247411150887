<template>
<div id="page-articulos">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Artículos" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--v-btn fab dark x-small color="blue" @click.native="open_componente" title="Calcular Max, Min, Reorden" v-tippy>
              <v-icon dark>mdi-call-split</v-icon>
            </v-btn-->
            <v-btn fab dark x-small color="success" @click.native="mostrarImportar()" title="Importar Artículos" v-tippy>
              <v-icon dark>mdi-file-import</v-icon>
            </v-btn>
            &nbsp;
            <v-btn fab dark x-small color="secondary" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>

          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2" @row-click="selectRow">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="codigo_barras" slot-scope="props">
                  {{props.row.codigo_barras.join(", ")}}
                </template>

                <template slot="categoria" slot-scope="props">
                  {{props.row.categoria[0]}}
                </template>
                
                <template slot="precio_compra_con_iva" slot-scope="props">
                    <div style="text-align:right">${{ formatNumberDec(props.row.precio_compra_con_iva,2) }}</div>
                </template>

                <template slot="precios_venta.0.precio_con_impuestos" slot-scope="props">
                    <div style="text-align:right">${{ formatNumberDec(props.row.precios_venta[0].precio_con_impuestos,2) }}</div>
                </template>

                <template slot="_attachments" slot-scope="props">
                  {{ props.row._attachments}}
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="green" @click.native="getRegistroImagen(props.row._id)" title="Agregar Imagen" v-tippy>
                      <v-icon>image</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="75%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs grow style="margin-bottom: 30px;" v-model="active_tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab key="datos">
            <v-card-title><b>Datos Generales</b></v-card-title>
          </v-tab>
          <v-tab key="impuestos" v-show="model.tiene_impuestos==1">
            <v-card-title><b>Impuestos del Artículo</b></v-card-title>
          </v-tab>
          <v-tab key="grupo" v-show="model.tiene_grupo==1">
            <v-card-title><b>Grupo de Artículos</b></v-card-title>
          </v-tab>
          <v-tab key="informacion" v-show="model.tiene_informacion==1">
            <v-card-title><b>Información Nutrimental</b></v-card-title>
          </v-tab>
          <v-tab key="existencias">
            <v-card-title><b>Inventario</b></v-card-title>
          </v-tab>

          <v-tab-item key="datos" style="margin: 20px;">
            <fieldset>
              <legend><strong>Información General</strong></legend>
              <v-form ref="form" lazy-validation>
                <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
                  <v-row>
                    <v-col sm="2" md="2" lg="2">
                      <v-text-field label="Alta" v-model="fechaUsuario" disabled></v-text-field>
                    </v-col>
                    <v-col sm="2" md="2" lg="2">
                      <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
                    </v-col>
                    <v-col sm="3" md="3" lg="3">
                      <v-checkbox @change="reiniciarImpuestos()" v-model="model.tiene_impuestos" label="Impuestos" :true-value="1" :false-value="0"></v-checkbox>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" justify="center">
                      <v-checkbox @change="reiniciarGrupo()" v-model="model.tiene_grupo" label="Grupo de Artículos" :true-value="1" :false-value="0"></v-checkbox>
                    </v-col>
                    <v-col sm="2" md="2" lg="2">
                      <v-checkbox @change="reiniciarInformacion()" v-model="model.tiene_informacion" label="Información Nutrimental" :true-value="1" :false-value="0"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" md="6" lg="6">
                      <v-autocomplete v-model="model.codigo_barras" :items="model.codigo_barras" :hide-no-data="true" :hide-selected="true" multiple chips small-chips deletable-chips label="Código de Barras" :append-outer-icon="'mdi-plus'"
                        :rules="[requiredArray]" @click:append-outer="modalCodigo=true; codigo_nuevo='';">
                      </v-autocomplete>                      
                    </v-col>
                    <v-col sm="6" md="6" lg="6">
                      <v-text-field label="Nombre" v-model="model.nombre" :rules="[rules.required]" @input="upperCaseInput('nombre')"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="12" lg="12">
                      <v-autocomplete v-model="model.descripcion" :items="descripciones" 
                        hide-no-data label="Seleccione Descripción" 
                        item-text="descripcion" item-value="descripcion"
                        :loading="isLoadingDescripcion"
                        :search-input.sync="search_descripcion"></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" md="6" lg="6">
                      <v-autocomplete v-model="model.producto_servicio" :items="productos" 
                        hide-no-data item-text="nombre" label="Producto/Servicio" 
                        :rules="[requiredObject]"
                        :loading="isLoadingServicio"
                        :search-input.sync="search_servicio"
                        return-object></v-autocomplete>
                    </v-col>
                    <v-col sm="6" md="6" lg="6">
                      <v-autocomplete v-model="model.unidad_medida" :items="unidades" 
                        hide-no-data item-text="nombre" label="Unidad de Medida" 
                        :rules="[requiredObject]"
                        :loading="isLoadingUnidad"
                        :search-input.sync="search_unidad"
                        return-object></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="10" md="10" lg="10">
                      <v-autocomplete v-model="model.categoria" :items="categorias" :hide-no-data="true" 
                        item-text="nombre" multiple chips small-chips deletable-chips 
                        item-value="nombre" label="Categoría"
                        :loading="isLoadingcategoria"
                        :search-input.sync="search_categoria"
                        :rules="[requiredArray]">
                      </v-autocomplete>
                    </v-col>
                    <v-col sm="2" md="2" lg="2">
                      <v-checkbox v-model="model.caducidad" label="Caducidad" :true-value="1" :false-value="0"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4" md="4" lg="4">
                      <v-text-field prepend-icon="attach_money" label="Precio Compra S/Impuestos" :rules="[rules.required, validaMaximo]" v-model="model.precio_compra" @keyup="validaFloat('precio_compra')" ref="precio_compra_ref"
                        @input="setPrecioCompra()"></v-text-field>
                    </v-col>
                    <v-col sm="4" md="4" lg="4">
                      <v-text-field prepend-icon="attach_money" label="Precio Compra C/Impuestos" :rules="[rules.required]" v-model="model.precio_compra_con_iva" disabled readonly></v-text-field>
                    </v-col>
                    <v-col sm="4" md="4" lg="4">
                      <v-autocomplete v-model="model.marca" :items="marcas" item-text="nombre" 
                      item-value="nombre" label="Marca/Modelo" :rules="[rules.required]" 
                      :loading="isLoadingMarca"
                      :search-input.sync="search_marca"
                      :hide-no-data="true"></v-autocomplete>
                    </v-col>
                  </v-row>

                </v-container>
              </v-form>
            </fieldset>
            <fieldset>
              <legend><strong>Precios de venta:</strong></legend>
              <v-form ref="formprecios" lazy-validation>
                <v-container grid-list-md>
                  <v-spacer></v-spacer>
                  <v-btn fab dark x-small color="green" v-on:click="addNewPrecio" title="Agregar Precio" v-tippy>
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <div v-for="(detalle, index) in model.precios_venta" v-bind:key="index">
                    <v-row style="flex-wrap: nowrap;">
                      <v-col cols="3">
                        <v-autocomplete :name="'nombre['+index+']'" v-model="detalle.tipo_precio" :items="tipos_precios" 
                          :hide-no-data="true" item-text="nombre" item-value="nombre" label="Tipo de Precio"
                          :rules="[rules.required, validaTipoPrecio]"></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :name="'nombre['+index+']'" label="% Aumento" type="text"
                        @blur="validaPrecioVenta(index)" class="center-input" autocomplete="off" :rules="[validaCantidadFloatNegativo]" @input="setPrecio(index)" v-model="detalle.porcentaje"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :name="'nombre['+index+']'" label="Precio S/Impuestos" type="text"
                          @input="setPrecioConImpuesto(index)"
                          @blur="validaPrecioVenta(index)"
                          class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat, validaMaximo]"
                          v-model="detalle.precio"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :name="'nombre['+index+']'" label="Precio C/Impuestos" type="text"
                        @input="setPrecioSinImpuesto(index)"
                        @blur="validaPrecioVenta(index)"
                        class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat, validaMaximo]"
                          v-model="detalle.precio_con_impuestos"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field :name="'nombre['+index+']'" label="De" class="center-input" type="text" autocomplete="off"
                          :rules="[rules.required, validaCantidadFloat, validaRango(index), validaMaximo]" v-model="detalle.de"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field :name="'nombre['+index+']'" label="a" class="center-input" type="text" autocomplete="off"
                          :rules="[rules.required, validaCantidadFloat, validaRango(index), validaMaximo]" v-model="detalle.a"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-btn fab dark x-small color="red" v-on:click="removePrecio(index)" title="Eliminar" v-tippy>
                          <v-icon dark>remove</v-icon>
                        </v-btn>
                        <v-btn fab dark x-small color="blue" v-on:click="solicitudEtiqueta(detalle)" title="Crear Solicitud de Etiqueta" v-tippy>
                          <v-icon dark>mdi-cash-refund</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-form>
            </fieldset>
          </v-tab-item>

          <v-tab-item key="impuestos" style="margin: 20px;">
            <fieldset>
              <legend><strong>Impuestos:</strong></legend>
              <v-form ref="formimpuestos" lazy-validation>
                <v-container grid-list-md>
                  <v-spacer></v-spacer>
                  <v-btn fab dark x-small color="green" v-on:click="addNewImpuesto" title="Agregar Impuesto" v-tippy>
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <div v-for="(detalle, index) in model.impuestos" v-bind:key="index">
                    <v-row style="flex-wrap: nowrap;">
                      <v-col cols="4">
                        <v-autocomplete :name="'nombre['+index+']'" v-model="detalle.impuesto" :items="impuestos" :hide-no-data="true" :hide-selected="true" item-text="nombre" @change="setTasa(index)" item-value="nombre" label="Tipo de Impuesto"
                          :rules="[rules.required, validaTipoImpuesto]"></v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field :name="'nombre['+index+']'" label="Tasa" type="text" class="center-input" readonly autocomplete="off" :rules="[validaCantidadFloat]" v-model="detalle.tasa"></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field :name="'nombre['+index+']'" label="Clave SAT" type="text" class="center-input" readonly autocomplete="off" v-model="detalle.clave"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-btn fab dark x-small color="red" v-on:click="removeImpuesto(index)" title="Eliminar" v-tippy>
                          <v-icon dark>remove</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-form>
            </fieldset>
          </v-tab-item>

          <v-tab-item key="grupo" style="margin: 20px;">
            <fieldset>
              <legend><strong>Artículos:</strong></legend>
              <v-container grid-list-md>
                <v-form ref="formgrupo" lazy-validation>
                  <v-row>
                    <v-col cols="7">
                      <v-autocomplete v-model="id_articulo" :items="articulos" :hide-no-data="true" :loading="isLoading" ref="selectarticulo" :search-input.sync="search" placeholder="Ingrese nombre de artículo para buscar" prepend-icon="search"
                        :hide-selected="true" item-text="nombre" item-value="_id" label="Artículo" :rules="[rules.required, validaArticulo]">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field label="Cantidad" type="text" class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadEntera]" v-model="cantidad_articulo"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn fab dark x-small color="green" v-on:click="addNewArticulo" title="Agregar Artículo" v-tippy>
                        <v-icon dark>add</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col>
                    <v-client-table ref="vuetablearticulos" :data="model.grupo_articulos" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                      <template slot="codigo_articulo" slot-scope="props">
                        {{props.row.codigo_articulo.join(", ")}}
                      </template>
                      <template slot="actions" slot-scope="props">
                        <v-btn x-small fab dark color="pink" @click.native="removeArticulo(props.row.id_articulo)" title="Eliminar" v-tippy>
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                    </v-client-table>
                  </v-col>
                </v-row>
              </v-container>
            </fieldset>
          </v-tab-item>

          <v-tab-item key="informacion" style="margin: 20px;">
            <fieldset>
              <legend><strong>Informacion Nutrimental:</strong></legend>
              <v-container grid-list-md>
                <v-form ref="forminformacion" lazy-validation>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field label="Información" ref="selecinformacion" type="text" class="center-input" autocomplete="off" :rules="[rules.required]" v-model="informacion"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field label="Cantidad/Datos" type="text" class="center-input" autocomplete="off" :rules="[rules.required]" v-model="cantidad_informacion"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete v-model="item_padre_informacion" :items="informacion_nutrimental_filtrada" hide-no-data hide-selected item-text="informacion" item-value="informacion" label="Item Padre" :hide-no-data="true"
                        :hide-selected="true"></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-btn fab dark x-small color="green" v-on:click="addNewInformacion" title="Agregar" v-tippy>
                        <v-icon dark>add</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col>
                    <v-client-table ref="vuetableinformacion" :data="model.informacion_nutrimental" :columns="columnsInformacion" :options="optionsInformacion" class="elevation-2 pa-2">
                      <template slot="informacion" slot-scope="props">
                        <span v-if="(props.row.item_padre_informacion!='' && props.row.item_padre_informacion!=null)">
                          &nbsp;&nbsp;&nbsp;&nbsp;{{props.row.informacion}}
                        </span>
                        <span v-else>
                          {{props.row.informacion}}
                        </span>

                      </template>
                      <template slot="actions" slot-scope="props">
                        <v-btn x-small fab dark color="pink" @click.native="removeInformacion(props.row)" title="Eliminar" v-tippy>
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                    </v-client-table>
                  </v-col>
                </v-row>
              </v-container>
            </fieldset>
          </v-tab-item>

          <v-tab-item key="existencias" style="margin: 20px;">
            <fieldset>
              <v-container grid-list-md>
                
                  <strong> EXISTENCIA TOTAL: {{ formatNumberDecCero(parseFloat(existencia_articulo),2) }}</strong>
                  <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                        :columns="columnsExistencias" :options="optionsExistencias">
                        <template slot="sucursal" slot-scope="props">
                            <div style="font-size:10px !important;" >{{props.row.sucursal}}</div>
                        </template>
                        <template slot="existencia" slot-scope="props">
                            <div style="font-size:10px !important;text-align:right;" >{{formatNumberDecCero(props.row.existencia,2)}}</div>
                        </template>
                    </v-client-table>
                                
              </v-container>
            </fieldset>
          </v-tab-item>

        </v-tabs>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalImportar" max-width="69%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-import</v-icon>&nbsp;
          <span class="subheading">
            <strong>Importar Artículos - Precios - Impuestos</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalImportar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

        <v-container grid-list-md>
          <v-row justify="center">
            <v-col sm="5" md="5" lg="5">
              <v-chip x-small>1</v-chip> Importar Artículos
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel :data="camposLayoutArticulos" :exportFields="encabezadoLayoutArticulos" name="Plantilla_Artículos.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Artículos" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <upload-excel-component title="Importar Artículos" idInput="articulosKey" :on-success="handleSuccessArticulos" :before-upload="beforeUpload" />
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel v-show="1==2" :data="articulos_all" :exportFields="encabezadoLayoutArticulos" name="Articulos.xlsx">
                <v-btn id="botonExportarArticulos" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>
              <v-btn fab dark x-small color="green darken-2" title="Exportar Articulos" @click="getArticulosExportarAll()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
            </v-col>
          </v-row>

          <v-row>
            &nbsp;
          </v-row>

          <v-row justify="center">
            <v-col sm="5" md="5" lg="5">
              <v-chip x-small>2</v-chip> Importar Impuestos de Artículos
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel :data="camposLayoutImpuestos" :exportFields="encabezadoLayoutImpuestos" name="Plantilla_Impuestos.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Impuestos" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <upload-excel-component title="Importar Impuestos" idInput="impuestosKey" :on-success="handleSuccessImpuestos" :before-upload="beforeUpload" />
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel v-show="1==2" :data="articulos_all" :exportFields="encabezadoLayoutImpuestosExportar" name="Articulos_Impuestos.xlsx">
                <v-btn id="botonExportarImpuestos" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>
              <v-btn fab dark x-small color="green darken-2" title="Exportar Impuestos" @click="getImpuestosArticulosAll()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
            </v-col>
          </v-row>

          <v-row>
            &nbsp;
          </v-row>

          <v-row justify="center">
            <v-col sm="5" md="5" lg="5">
              <v-chip x-small>3</v-chip> Importar Precios de Venta de Artículos
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel :data="camposLayoutPrecios" :exportFields="encabezadoLayoutPrecios" name="Plantilla_Precios.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Precios" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <upload-excel-component title="Importar Precios" idInput="preciosKey" :on-success="handleSuccessPrecios" :before-upload="beforeUpload" />
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel v-show="1==2" :data="articulos_all" :exportFields="encabezadoLayoutPreciosExportar" name="Articulos_Precios.xlsx">
                <v-btn id="botonExportarPrecios" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>
              <v-btn fab dark x-small color="green darken-2" title="Exportar Precios" @click="getPreciosArticulosAll()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
            </v-col>
          </v-row>
          <v-row>
            &nbsp;
          </v-row>

          <div id="datagrid" v-on:keyup.enter="registros.importaciones = []; $refs.pagination_pw_importaciones.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

            <v-client-table ref="vuetableimportaciones" :data="registros.importaciones" :columns="columns_importaciones" :options="options_importaciones" class="elevation-2 pa-2">
              <template slot="noResults">
                <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
              </template>

              <template slot="actions" slot-scope="props">
                <v-btn x-small fab dark color="green" @click.native="descargarAdjunto(props.row)" title="Descargar" v-tippy>
                  <v-icon>file_download</v-icon>
                </v-btn>
                &nbsp;
                  <v-btn x-small fab dark color="pink" @click.native="eliminarAdjunto(props.row)"
                        title="Eliminar" v-tippy>
                        <v-icon>delete</v-icon>
                    </v-btn>
              </template>
            </v-client-table>
            <PaginacionComponent ref="pagination_pw_importaciones" :props="props_paginacion_importaciones"></PaginacionComponent>
          </div>


        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalImportar = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalCodigo" max-width="30%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-import</v-icon>&nbsp;
          <span class="subheading">
            <strong>Agregar Código de Barras</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCodigo = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form_codigo" lazy-validation>
          <v-container grid-list-md>
            <v-row justify="center">
              <v-col sm="12">
                <v-text-field label="Código de Barras" v-model="codigo_nuevo" :rules="[rules.required]" @input="codigo_nuevo=codigo_nuevo.toString().toUpperCase()"
                  v-on:keydown.enter.prevent='1'
                  @keyup.enter="agregar_codigo()" ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="agregar_codigo">
          <v-icon>cancel</v-icon> Agregar
        </v-btn>
        <v-btn @click.native="modalCodigo = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalImagen" max-width="35%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Agregar/Editar Imagen</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalImagen = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col>
                <v-file-input label="Seleccione una imagen"
                        :rules="[rules.required]"
                        v-model="file_adjunto"
                        :show-size="1000"
                        accept="image/png, image/jpeg"
                    ></v-file-input>
              </v-col>
            </v-row>
            <v-row v-show="(imagen_adjunto!=null && imagen_adjunto!='')">
              <v-col>
                <img style="" :src="imagen_adjunto.source" width="200" height="200" heigth="auto">
              </v-col>
                <v-col>
                    <v-btn x-small fab dark color="success" @click.native="descargarImagen(imagen_adjunto)"
                            title="Descargar" v-tippy>
                            <v-icon>file_download</v-icon>
                        </v-btn>&nbsp;
                      <v-btn x-small fab dark color="pink" @click.native="eliminarImagen(imagen_adjunto)"
                            title="Eliminar" v-tippy>
                            <v-icon>delete</v-icon>
                        </v-btn>
                </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalImagen = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="adjuntarImagen()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <HistoricoMasivoComponent :props="props_historico"></HistoricoMasivoComponent>
</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import HistoricoMasivoComponent from '@/components/HistoricoMasivoComponent.vue';
import ImageUploader from 'vue-image-upload-resize';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent,
    "HistoricoMasivoComponent": HistoricoMasivoComponent,
    "ImageUploader": ImageUploader
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    model_filters_importaciones: {
      handler(val) {

      },
      deep: true,
    },
    search_descripcion(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return
      // Si todavía esta buscando
      if (this.isLoadingDescripcion) return
      this.isLoadingDescripcion = true
      let data = {
          "selector": {"type":"descripciones"},                  
      };

      window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
              if (response.data.docs.length > 0){
                  this.descripciones = response.data.docs;
              }else
                  this.descripciones = [];
          })
          .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener descripciones.",
                  footer: ""
              });
          }).then(()=>{ this.isLoadingDescripcion=false; });
    },
    search_servicio(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return
      // Si todavía esta buscando
      if (this.isLoadingServicio) return
      this.isLoadingServicio = true
      let data = {
          "selector": {"type": "sat_productos_servicios",
          "estatus": "Activo"},
          "use_index":"_design/0f7c78039b8c94ad68b43b3f49581473d23dd9c1"
      };

      window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
              if (response.data.docs.length > 0){
                  this.productos = response.data.docs;
              }else
                  this.productos = [];
          })
          .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener los productos/servicios del SAT.",
                  footer: ""
              });
          }).then(()=>{ this.isLoadingServicio=false; });
    },
    search_unidad(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return
      // Si todavía esta buscando
      if (this.isLoadingUnidad) return
      this.isLoadingUnidad = true
      let data = {
          "selector": {"type": "sat_unidades_medida",
          "estatus": "Activo"},
      };

      window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
              if (response.data.docs.length > 0){
                  this.unidades = response.data.docs;
              }else
                  this.unidades = [];
          })
          .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener las unidades de medida del SAT.",
                  footer: ""
              });
          }).then(()=>{ this.isLoadingUnidad=false; });
    },
    search_categoria(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return
      // Si todavía esta buscando
      if (this.isLoadingcategoria) return
      this.isLoadingcategoria = true
      let data = {
          "selector": {"type": "categorias",
          "estatus": "Activo"},
          "fields": ["nombre", "_id"]
      };

      window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
              if (response.data.docs.length > 0){
                  this.categorias = response.data.docs;
              }else
                  this.categorias = [];
          })
          .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener las categorias.",
                  footer: ""
              });
          }).then(()=>{ this.isLoadingcategoria=false; });
    },
    search_marca(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return
      // Si todavía esta buscando
      if (this.isLoadingMarca) return
      this.isLoadingMarca = true
      let data = {
          "selector": {"type": "marcas",
          "estatus": "Activo"},
          "fields": ["nombre", "_id"]
      };

      window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
              if (response.data.docs.length > 0){
                  this.marcas = response.data.docs;
              }else
                  this.marcas = [];
          })
          .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener las marcas.",
                  footer: ""
              });
          }).then(()=>{ this.isLoadingMarca=false; });
    },
    
    search(val) {

      if (val == null || val.length < 1) return

      // Si todavía esta buscando
      if (this.isLoading) return

      this.isLoading = true

      let data = {
        "selector": {
          "type": "articulos",          
          "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos = response.data.docs;
          } else
            this.articulos = [];

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });

        }).then(() => {
          this.isLoading = false;
        });
    },


  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.catalogos.articulos')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }

    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf___attachments')[0];
    select.addEventListener('change', this.onImagenChanged);
  },
  created: function() {
    this.buscar_combos();
  },
  data() {
    return {
      selectedRowIndex: null,
      imagen_adjunto:"",
      modalImagen: false,
      hasImage: false,
      file_adjunto: null,
      TASA_IVA: 16, //Por default
      informacion_nutrimental_filtrada: [],
      informacion: "",
      cantidad_informacion: "",
      item_padre_informacion: "",
      columnsInformacion: ["informacion", "cantidad_informacion", "actions"],
      optionsInformacion: {
        filterable: ["informacion", "cantidad_informacion"],
        headings: {
          "informacion": "Información",
          "cantidad_informacion": "Cantidad/Datos",
          "actions": "Opciones",
        },

      },
      columnsArticulos: ["codigo_articulo", "nombre_articulo", "cantidad", "actions"],
      optionsArticulos: {
        filterable: ["codigo_articulo", "nombre_articulo", "cantidad"],
        headings: {
          "codigo_articulo": "Código",
          "nombre_articulo": "Artículo",
          "cantidad": "Cantidad",
          "actions": "Opciones",
        },
      },
      props_historico: {
        modal: false,
        sucursales: window.sucursales
      },
      search: "",
      search_descripcion:"",
      isLoadingDescripcion:false,
      search_marca:"",
      isLoadingMarca:false,
      search_precios:"",
      isLoadingPrecios:false,
      search_servicio:"",
      isLoadingServicio:false,
      search_unidad:"",
      isLoadingUnidad:false,
      search_categoria:"",
      isLoadingcategoria:false,
      id_articulo: "",
      cantidad_articulo: "",
      isLoading: false,
      productos_all: [],
      marcas_all: [],
      articulos_all: [],
      unidades_all: [],
      tipos_precios_all: [],
      impuestos_all: [],
      //Articulos
      encabezadoLayoutArticulos: {
        codigo_barras: "codigo_barras",
        nombre: "nombre",
        descripcion: "descripcion",
        categoria: "categoria",
        marca: "marca",
        producto_servicio: "producto_servicio",
        unidad_medida: "unidad_medida",
        precio_compra: "precio_compra",
        precio_compra_con_iva: "precio_compra_con_iva",
        caducidad: "caducidad",
        estatus: "estatus",
      },      
      camposLayoutArticulos: [{
        codigo_barras: "[Código de Barras]",
        nombre: "[Nombre corto del artículo]",
        descripcion: "[Descripción del artículo. Ver opciones en catálogo de artículos (Pieza, Bulto, Caja, etc)]",
        categoria: "[Categorías separadas por coma]",
        marca: "[Marca del artículo]",
        producto_servicio: "[Clave SAT de Producto/Servicio]",
        unidad_medida: "[Clave SAT Unidad Medida]",
        precio_compra: "[Precio de compra S/Impuestos]",
        precio_compra_con_iva: "[Precio de compra C/Impuestos]",
        caducidad: "[Ingrese un 1 si tiene caducidad]",
        estatus: "[Activo|Inactivo]",
      }],
      camposObligatoriosArticulos: [
        "codigo_barras",
        "nombre",
        "categoria",
        "marca",
        "producto_servicio",
        "unidad_medida",
        "precio_compra",
        "precio_compra_con_iva",
        "estatus",
      ],
      //Precios
      encabezadoLayoutPrecios: {
        codigo_barras: "codigo_barras",
        tipo_precio: "tipo_precio",
        precio: "precio",
        de: "de",
        a: "a"
      },
      encabezadoLayoutPreciosExportar: {
        codigo_barras: "codigo_barras",
        nombre:"nombre",
        tipo_precio: "tipo_precio",
        "precio_sin_impuestos": "precio",
        "precio_final": "precio_con_impuestos",
        de: "de",
        a: "a"
      },
      camposLayoutPreciosExportar: [{
        codigo_barras: "[Código de Barras]",
        nombre:"[Nombre del Articulo]",
        tipo_precio: "[Tipo de precio. Ver catálogo]",
        precio: "[Precio de Venta Sin Impuestos]",
        precio_con_impuestos:"[Precio de Venta Con Impuestos]",
        de: "[Cantidad de artículos inicial]",
        a: "[Cantidad de artículos final]"
      }],
      camposLayoutPrecios: [{
        codigo_barras: "[Código de Barras]",
        tipo_precio: "[Tipo de precio. Ver catálogo]",
        precio: "[Precio de Venta Con Impuestos (Precio Final)]",
        de: "[Cantidad de artículos inicial]",
        a: "[Cantidad de artículos final]"
      }],
      camposObligatoriosPrecios: [
        "codigo_barras",
        "tipo_precio",
        "precio",
        "de",
        "a",
      ],
      //Impuestos
      encabezadoLayoutImpuestos: {
        codigo_barras: "codigo_barras",
        impuesto: "impuesto",
      },      
      encabezadoLayoutImpuestosExportar: {
        codigo_barras: "codigo_barras",
        nombre:"nombre",
        impuesto: "impuesto",
        tasa: "tasa",
      },      
      //van vacíos para solo descargar layout con encabezados
      camposLayoutImpuestos: [{
        codigo_barras: "[Código de Barras]",
        impuesto: "[Nombre del impuesto. Ver catálogo]",
      }],
      camposObligatoriosImpuestos: [
        "codigo_barras",
        "impuesto",
      ],
      modalImportar: false,
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      columns_importaciones: [
        "fecha",
        "nombre_usuario",
        "archivo",
        "tipo",
        "estatus",
        "errores",
        "actions"
      ],
      options_importaciones: {
        filterable: ["fecha", "nombre_usuario", "archivo", "tipo", "estatus", "errores"],
        sortable: ["fecha", "nombre_usuario", "archivo", "tipo", "estatus", "errores"],

        headings: {
          fecha: "Fecha",
          nombre_usuario: "Usuario",
          archivo: "Archivo cargado",
          estatus: "Estatus",
          actions: "Archivo"
        },
      },
      model_filters_importaciones: "",
      props_paginacion_importaciones: {
        infiniteHandler: this.infiniteHandler_importaciones,
        total_registros: 0
      },
      active_tab: "datos",
      menu1: false,
      fecha_parsed: "",
      marcas: [],
      productos: [],
      unidades: [],
      articulos: [],
      impuestos: [],
      categorias: [],
      tipos_precios: [],
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      columns: [
        "codigo_barras",
        "nombre",
        "descripcion",
        "categoria",
        "marca",
        "precio_compra_con_iva",
        "precios_venta.0.precio_con_impuestos",
        "_attachments",
        "estatus",
        "actions"
      ],
      show_loading: true,
      options: {
        rowClassCallback: this.getRowClass,
        filterable: ["codigo_barras", "nombre", "descripcion", "categoria", "marca", "precio_compra_con_iva", "_attachments", "estatus"],
        sortable: ["codigo_barras", "nombre", "descripcion", "categoria", "marca", "precio_compra_con_iva", "_attachments", "estatus"],

        headings: {
          "codigo_barras": "Código de Barras",
          "descripcion": "Descripción",
          "categoria": "Categoría",
          "marca": "Marca/Modelo",
          "precio_compra_con_iva": "Precio Compra",
          "precios_venta.0.precio_con_impuestos":"Precio Venta",
          "_attachments":"Imagen",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns: {
          
            estatus: [{
                    id: 'Activo',
                    text: "Activo"
                },
                {
                    id: 'Inactivo',
                    text: "Inactivo"
                }
            ],
            _attachments: [{
                    id: 'Si',
                    text: "Si"
                },
                {
                    id: 'No',
                    text: "No"
                }
            ],            
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catálogos",
          disabled: true,
          href: ""
        },
        {
          text: "Artículos",
          href: ""
        }
      ],

      modal: false,
      modalCodigo: false,
      codigo_nuevo: '',
      update: false,
      descripciones: [],
      descripciones_all: [],
      arr_descripciones: [],
      model_adjunto: {},
      model: {
        _id: "",
        _rev: "",
        type: "articulos",
        codigo_barras: [],
        nombre: "",
        created_at: "",
        estatus: "Activo",
        descripcion: "",
        categoria: "",
        caducidad: "",
        tiene_impuestos: "",
        tiene_grupo: "",
        tiene_informacion: "",
        marca: "",
        precios_venta: [],
        impuestos: [],
        grupo_articulos: [],
        informacion_nutrimental: [],
        producto_servicio: "",
        unidad_medida: "",
        precio_compra: "",
        precio_compra_con_iva: "",
        //nuevos
        equivalencias: [],
        proveedores: []
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      loading_records: false,
      registros: {
        selected: [],
        items: [],
        importaciones: []
      },
      articulo_existencias: [],
      existencia_articulo:"",
      columnsExistencias: ["sucursal","existencia"],
      optionsExistencias: {
          filterable: false,
      },

    };
  },

  methods: {
    onStatusChanged(event) {
      this.registros.items = []; 
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');      
    },
    onImagenChanged(event) {
      this.registros.items = []; 
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');      
    },
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    getNombreSucursal: function(id_sucursal) {
        var filter = window.sucursales.find(e => e._id == id_sucursal);
        if (filter)
            return filter.nombre;
        else
            return "";
    },
    formatNumberDecCero: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            numero = 0;
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else{
          if(parseFloat(numero) == parseInt(numero))
            return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
          else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
            
    },
    ver_existencias: async function(id_articulo) {
        let n = 0;
        var self = this;
        await window.funciones_lotes.consultaExistencia({
            "id_articulo": id_articulo
        }).then(result => {
            if (result.length > 0) {

                result.forEach(el => {
                    n += el.existencia
                    el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                });
                this.articulo_existencias = result;
                this.existencia_articulo = n;
            } else {
                this.existencia_articulo = 0;
                this.articulo_existencias = [];
            }            
            //console.log("se asigno la existencia");
        }).catch(err => {
            console.log("error", err);
            this.existencia_articulo = 0;
            this.articulo_existencias = [];
        });
    },

    //this.actualizar_precios_compra();//Script para ponerle el precio de compra con iva a los articulos
    actualizar_precios_compra: async function() {

      var self = this;

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: {
              "type": "articulos",
              "precio_compra_con_iva": {
                "$exists": false
              }
            }
          },
        }).then(async response => {
          if (response.data.docs.length) {
            for (var ka in response.data.docs) {
              let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_precio_compra_script/" + response.data.docs[ka]._id;
              //let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_precio_compra/4fc280aaceffa5bf2a6516be1a4a7398";

              var data = {
                precio_compra: 10.01,
                impuesto_iva: self.TASA_IVA,
              };

              await window.axios
                .post(url, data)
                .then(response2 => {
                  if (response2.data == true) {
                    console.log("Actualizado: ", response.data.docs[ka]["nombre"]);
                  } else {
                    console.log("No Actualizado: ", response.data.docs[ka]["nombre"]);
                  }
                })
                .catch(error => {
                  console.log("ERROR: ", error);
                  console.log("Error: ", response.data.docs[ka]["nombre"]);
                });


            }
          }
        })
        .catch(error => {
          console.log(error)
        });

    },
    //this.actualizar_precios_venta(); //Script para ponerle el precio con impuestos a los articulos
    actualizar_precios_venta: async function(row) {

      var self = this;
      /*self.getArticulosAll().then(result => {
        for(var ka in self.articulos_all){*/
      //let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_precio_venta_script/" + self.articulos_all[ka]._id;


      let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE +
        "/_design/funciones_articulos/_update/actualizar_precio_venta/4fc280aaceffa5bf2a6516be1a4a7398";

      var data = {
        tipo_precio: "Público General",
        precio: 10,
      };

      window.axios
        .post(url, data)
        .then(response => {
          if (response.data == true) {
            console.log("Actualizado");
          } else {
            console.log("No Actualizado");
          }
        })
        .catch(error => {
          console.log("Error: ", error);
        });


      /* }

      });*/



    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "articulos"
      };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if (x == "codigo_barras") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x]
              }
            }
          } else if (x == "categoria") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x]
              }
            }
          } else if (x == "_attachments") {            
            where[x] = {
              "$exists": this.model_filters[x]=="Si"?true:false,
            }
          } else if (x == "precio_compra_con_iva") {
             //where[x] = parseFloat(this.model_filters[x])
            where["$or"] = [
                {"precio_compra_con_iva": parseFloat(this.model_filters[x])}, {"precio_compra_con_iva": {"$regex": "(?i)" + this.model_filters[x]}}
              ]
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }            
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            //buscar items nuevos, para que no se dupliquen
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            for(var k in nuevos){
              if(nuevos[k]._attachments != undefined && nuevos[k]._attachments != null){
                nuevos[k]._attachments = "Si";
              } else {
                nuevos[k]._attachments = "No";
              }
            }
            this.registros.items = this.registros.items.concat(nuevos);
            this.props_paginacion.total_registros = this.registros.items.length;            
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    infiniteHandler_importaciones: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.importaciones.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "articulos_layout",
        "nombre_usuario": {
          "$exists": true
        },
        "fecha": {
          "$exists": true
        },
        "archivo": {
          "$exists": true
        },
        "tipo": {
          "$exists": true
        },
        "estatus": {
          "$exists": true
        },
        "errores": {
          "$exists": true
        }
      };

      this.options_importaciones.filterable.forEach(x => {
        if (this.model_filters_importaciones[x]) {
          if (x == "codigo_barras") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters_importaciones[x]
              }
            }
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters_importaciones[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{
              "fecha": "desc"
            }],
            use_index: "_design/7a4054f61ed8ea0e069b59363ee61b5910029ade"
          },
        }).then(response => {
          if (response.data.docs.length) {
            for (var k in response.data.docs) {
              response.data.docs[k].fecha = window.moment(response.data.docs[k].fecha).format("YYYY-MM-DD HH:mm");
            }
            var unique_records = new Set(this.registros.importaciones.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.importaciones = this.registros.importaciones.concat(nuevos);
            this.props_paginacion_importaciones.total_registros = this.registros.importaciones.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    mostrarImportar: function() {
      this.modalImportar = true;
      setTimeout(() => {
        this.model_filters_importaciones = this.$refs.vuetableimportaciones.$refs.table.query;
      }, 100);

    },
    getImpuestoIVA: function() {
      let data = {
        "selector": {
          "type": "configuracion"
        },
        "fields": ["_id", "impuestos"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          this.TASA_IVA = 16; //Por default

          if (response.data.docs.length > 0) {
            var records = response.data.docs[0];
            for (var k in records.impuestos) {
              if (records.impuestos[k].clave == "002" && parseFloat(records.impuestos[k].tasa) > 0) { //Clave IVA 002
                this.TASA_IVA = parseFloat(records.impuestos[k].tasa);
              }
            }

          }
        }).catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el impuesto IVA.",
            footer: ""
          });
        });
    },
    reiniciarImpuestos: function() {
      if (this.model.tiene_impuestos != null && this.model.tiene_impuestos == 0)
        this.model.impuestos = [];
        this.setPrecioImpuestoArray();
    },
    reiniciarGrupo: function() {
      if (this.model.tiene_grupo != null && this.model.tiene_grupo == 0)
        this.model.grupo_articulos = [];
      //En grupo de articulos se muestran todos excepto el que esta creando/editando
      if (this.model.tiene_grupo != null && this.model.tiene_grupo == 1)
        this.articulos = this.registros.items.filter(e => e.nombre != this.model.nombre)
    },
    reiniciarInformacion: function() {
      if (this.model.tiene_informacion != null && this.model.tiene_informacion == 0)
        this.model.informacion_nutrimental = [];
    },
    addNewPrecio: function() {
      this.model.precios_venta.push(Vue.util.extend({}, []));
    },
    setPrecioArray: function() {
      for (var idx in this.model.precios_venta) {
        this.setPrecio(idx);
      }
    },
    setPrecio: function(index) {
      var impuestos = 0;
      var porciento = this.model.precios_venta[index].porcentaje;
      var precio_compra = this.model.precio_compra;
      //if(porciento != "" && precio_compra!=null && precio_compra!="" && parseFloat(porciento) >= 0 ){
      if (this.validaCantidadFloat(porciento) === true && this.validaCantidadFloat(precio_compra) === true) {
        this.model.precios_venta[index].precio = parseFloat(precio_compra) * (1 + (parseFloat(porciento) / 100));
        this.model.precios_venta[index].precio = this.model.precios_venta[index].precio.toFixed(2);
        for (var k in this.model.impuestos) {
          impuestos += parseFloat(this.model.impuestos[k]["tasa"]);
        }
        this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio) * (1 + (parseFloat(impuestos) / 100));
        this.model.precios_venta[index].precio_con_impuestos = this.model.precios_venta[index].precio_con_impuestos.toFixed(2);
        //}
      } else {
        this.model.precios_venta[index].precio = "";
      }
    },
    setPrecioImpuestoArray: function() {
      for (var idx in this.model.precios_venta) {
        this.setPrecioConImpuesto(idx);
      }
      this.setPrecioCompra();
    },
    setPrecioConImpuesto: function(index) {
      //Se calcula el precio con impuesto y el porcentaje
      var porciento = 0;
      var precio_venta = this.model.precios_venta[index].precio;
      var precio_compra = this.model.precio_compra;
      if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
        if (precio_venta != null && precio_venta != "") {
          if (this.validaCantidadFloat(precio_venta) === true) {
            for (var k in this.model.impuestos) {
              porciento += parseFloat(this.model.impuestos[k]["tasa"]);
            }
            this.model.precios_venta[index].precio_con_impuestos = parseFloat(precio_venta) * (1 + (parseFloat(porciento) / 100));
            this.model.precios_venta[index].precio_con_impuestos = this.model.precios_venta[index].precio_con_impuestos.toFixed(2);
            this.model.precios_venta[index].porcentaje = ((precio_venta / precio_compra) - 1) * 100;
            this.model.precios_venta[index].porcentaje = this.model.precios_venta[index].porcentaje.toFixed(2);
          }
        } else {
          this.model.precios_venta[index].precio_con_impuestos = "";
        }

      } else {
        this.$swal({
          type: "error",
          title: "Ingrese Precio de Compra",
          text: "Debe ingresar el precio de compra.",
          footer: ""
        }).then(() => {
          setTimeout(() => {
            this.$nextTick(() => this.$refs.precio_compra_ref.focus());
          }, 100);
        });
      }

    },
    setPrecioSinImpuesto: function(index) {
      //Se calcula el precio sin impuesto y el porcentaje
      var porciento = 0;
      var precio_venta = this.model.precios_venta[index].precio_con_impuestos;
      var precio_compra = this.model.precio_compra;
      if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
        if (precio_venta != null && precio_venta != "") {
          if (this.validaCantidadFloat(precio_venta) === true) {
            for (var k in this.model.impuestos) {
              porciento += parseFloat(this.model.impuestos[k]["tasa"]);
            }
            this.model.precios_venta[index].precio = parseFloat(precio_venta) / (1 + (parseFloat(porciento) / 100));
            this.model.precios_venta[index].precio = this.model.precios_venta[index].precio.toFixed(2);
            this.model.precios_venta[index].porcentaje = ((this.model.precios_venta[index].precio / precio_compra) - 1) * 100;
            this.model.precios_venta[index].porcentaje = this.model.precios_venta[index].porcentaje.toFixed(2);
          }
        } else {
          this.model.precios_venta[index].precio = "";
        }

      } else {
        this.$swal({
          type: "error",
          title: "Ingrese Precio de Compra",
          text: "Debe ingresar el precio de compra.",
          footer: ""
        }).then(() => {
          this.$nextTick(() => this.$refs.precio_compra_ref.focus());
        });
      }
    },
    validaPrecioVenta: function(index){
      var precio_venta = this.model.precios_venta[index].precio_con_impuestos;
      var precio_compra = this.model.precio_compra_con_iva;

      if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
        if (precio_venta != null && precio_venta != "" && this.validaCantidadFloat(precio_venta) === true) {
          if (parseFloat(precio_compra) >= parseFloat(precio_venta)) {
            this.$swal({
              type: "warning",
              title: "!Cuidado¡",
              text: "El precio de compra es mayor o igual al precio de venta. Favor de verificar.",
              footer: ""
            });
          }
        }
      }
    },
    setPrecioCompra: function() {
      var precio_compra = this.model.precio_compra;
      if (precio_compra != null && precio_compra != "") {
        if (this.validaCantidadFloat(precio_compra) === true) {
          var iva = 0;
          for (var k in this.model.impuestos) {
              iva += parseFloat(this.model.impuestos[k]["tasa"]);            
          }
          this.model.precio_compra_con_iva = parseFloat(precio_compra) * (1 + (iva / 100));
          this.model.precio_compra_con_iva = this.model.precio_compra_con_iva.toFixed(4);
        }
      } else {
        this.model.precio_compra_con_iva = "";
      }
    },
    removePrecio: function(index) {
      Vue.delete(this.model.precios_venta, index);
    },
    validaTipoPrecio: function(val) {
      if (val != null && val != "") {
        var precios = this.model.precios_venta.filter(e => e.tipo_precio == val);
        if (precios.length >= 2)
          return "No debe repetir el tipo de precio";
      }
      return true;
    },
    addNewImpuesto: function() {
      this.model.impuestos.push(Vue.util.extend({}, []));
    },
    removeImpuesto: function(index) {
      Vue.delete(this.model.impuestos, index);
      this.setPrecioImpuestoArray();
    },
    validaTipoImpuesto: function(val) {
      if (val != null && val != "") {
        var impuestos = this.model.impuestos.filter(e => e.impuesto == val);
        if (impuestos.length >= 2)
          return "No debe repetir el tipo de impuesto";
      }
      return true;
    },
    getNombreArticulo: function(id_articulo) {
      var filter = this.articulos.find(e => e._id == id_articulo);
      if (filter)
        return filter.nombre;
      else
        return ""
    },
    getCodigoArticulo: function(id_articulo) {
      var filter = this.articulos.find(e => e._id == id_articulo);
      if (filter)
        return filter.codigo_barras;
      else
        return ""
    },
    addNewArticulo: function() {
      if (this.$refs.formgrupo.validate()) {

        //Buscar articulo, si ya extá aumentar la cantidad
        var filter = this.model.grupo_articulos.filter(e => {
          return e.id_articulo == this.id_articulo;
        });

        if (filter.length > 0) {
          this.model.grupo_articulos.forEach(e => {
            if (e.id_articulo == this.id_articulo)
              e.cantidad += parseFloat(this.cantidad_articulo);
          });
        } else { //Es nuevo
          var articuloNew = {};
          articuloNew.id_articulo = this.id_articulo;
          articuloNew.codigo_articulo = this.getCodigoArticulo(articuloNew.id_articulo);
          articuloNew.nombre_articulo = this.getNombreArticulo(articuloNew.id_articulo);
          articuloNew.cantidad = parseFloat(this.cantidad_articulo);
          this.model.grupo_articulos.push(articuloNew);
        }

        this.id_articulo = "";
        this.cantidad_articulo = "";
        this.$nextTick(() => this.$refs.selectarticulo.focus());
      }

    },
    solicitudEtiqueta: function(detalle) {
      var self = this;
      var etq = {
        type: "etiquetas",
        fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
        estatus: "Pendiente",
        articulos: [{
          id_articulo: self.model._id,
          nombre_articulo: self.model.nombre,
          descripcion: self.model.descripcion,
          codigo_articulo: self.model.codigo_barras,
          cantidad: 1,
          precio: parseFloat(detalle.precio_con_impuestos != undefined ? detalle.precio_con_impuestos : detalle.precio),
          tipo_precio: detalle.tipo_precio,
          mayoreo: detalle.de
        }],
        usuario: self.$local_storage.get("sb_usuario"),
        observaciones: "Catálogo de Artículos",
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
        .then(response => {
          console.log("Response", response);
          window.dialogLoader.showSnackbar('Solicitud Enviada', {
            color: 'success'
          });
        })
        .catch(error => {
          console.log(error);
          window.dialogLoader.showSnackbar('Ocurrio un error al crear la solicitud', {
            color: 'error'
          });
        });

    },
    removeArticulo: function(idArticulo) {
      var filter = this.model.grupo_articulos.filter(e => {
        return e.id_articulo != idArticulo;
      });
      this.model.grupo_articulos = filter;

    },
    validaArticulo: function(val) {
      if (val != null && val != "") {
        if (this.update && val == this.model._id)
          return "No debe seleccionar el artículo original";
      }
      return true;
    },
    get_hijos_informacion: function(arreglo, item_padre) {
      var hijos = [];
      for (var idx in arreglo) {
        if (arreglo[idx]["item_padre_informacion"] == item_padre) {
          arreglo[idx]["informacion"] = "    " + arreglo[idx]["informacion"].toString().trim();
          hijos.push(arreglo[idx]);
        }

      }
      return hijos;
    },
    filter_informacion_nutrimental: function() {
      try {
        this.informacion_nutrimental_filtrada = [];
        for (var idx in this.model.informacion_nutrimental) {
          if (this.model.informacion_nutrimental[idx]["item_padre_informacion"] == null || this.model.informacion_nutrimental[idx]["item_padre_informacion"] == "")
            this.informacion_nutrimental_filtrada.push(this.model.informacion_nutrimental[idx]);
        }
      } catch (error) {
        this.informacion_nutrimental_filtrada = [];
      }

    },
    addNewInformacion: function() {
      if (this.$refs.forminformacion.validate()) {
        try {

          var filter = this.model.informacion_nutrimental.filter(e => {
            return e.informacion.trim() == this.informacion.trim() && e.cantidad_informacion.trim() == this.cantidad_informacion.trim();
          });

          if (filter.length == 0) {
            var articuloNew = {
              "informacion": this.informacion.trim(),
              "cantidad_informacion": this.cantidad_informacion.trim(),
              item_padre_informacion: this.item_padre_informacion
            };
            this.model.informacion_nutrimental.push(articuloNew);
          }

          //Reordenar el listado poniendo debajo los que son hijos
          var copyData = Object.assign([], this.model.informacion_nutrimental);
          this.model.informacion_nutrimental = [];

          for (var idx in copyData) {
            if (copyData[idx]["item_padre_informacion"] == "" || copyData[idx]["item_padre_informacion"] == null) {
              this.model.informacion_nutrimental.push(copyData[idx]);
              var hijos = this.get_hijos_informacion(copyData, copyData[idx]["informacion"]);
              if (hijos.length > 0) { //Si tiene hijos se ponen en seguida
                for (var idxh in hijos) {
                  this.model.informacion_nutrimental.push(hijos[idxh]);
                }
              }
            }
          }
          this.filter_informacion_nutrimental();

          this.informacion = "";
          this.cantidad_informacion = "";
          this.item_padre_informacion = "";
          this.$nextTick(() => this.$refs.selecinformacion.focus());

        } catch (error) {
          console.log(error);
        }

      }

    },
    removeInformacion: function(row) {
      var filter = this.model.informacion_nutrimental.filter(e => {
        return e.informacion != row.informacion || e.cantidad_informacion != row.cantidad_informacion;
      });
      this.model.informacion_nutrimental = filter;
      this.filter_informacion_nutrimental();
    },
    setTasa: function(index) {
      var tipo = this.model.impuestos[index].impuesto;
      var filter = this.impuestos.find(e => e.nombre == tipo)
      if (filter) {
        this.model.impuestos[index].tasa = filter.tasa;
        this.model.impuestos[index].clave = filter.clave;
      }
      this.setPrecioImpuestoArray();
    },
    openModal: function(type) {
      if (type === 1) {
        this.update = false;
        this.model.type = "articulos";
        this.model.codigo_barras = [];
        this.model.nombre = "";
        this.model.estatus = "Activo";
        this.model.descripcion = "";
        this.model.categoria = "";
        this.model.caducidad = "";
        this.model.precio_compra = "";
        this.model.precio_compra_con_iva = "";
        this.model.marca = "";
        this.model.producto_servicio = "";
        this.model.unidad_medida = "";
        this.model.precios_venta = [];
        this.model.tiene_impuestos = 0;
        this.model.impuestos = [];
        this.model.tiene_grupo = 0;
        this.model.grupo_articulos = [];
        this.model.tiene_informacion = 0;
        this.model.informacion_nutrimental = [];
        this.model.created_at = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
        this.model.equivalencias = [];
        this.model.proveedores = [];
        this.getTiposPrecio();
      } else {
        this.update = true;        
      }

      this.modal = true;
      this.active_tab = "datos";
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validaFloat: function(campo) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      let value = this.model[campo];
      if (!pattern.test(value)) {
        this.model[campo] = this.model[campo].substring(0, this.model[campo].length - 1);
        this.validaFloat(campo);
      }

    },
    validaMaximo: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && pattern.test(value)) {
        if(parseFloat(value) > 100000)
          return "Cantidad demasiado grande";
      } 
      return true;

    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    validaCantidadFloatNegativo: function(value) {
      const pattern = /^[\-]{0,1}[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad entera no válida"
      } else
        return true;

    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },
    requiredArray: function(val) {
      if (val != null && val.length > 0) {
        return true;
      } else
        return "Este campo es requerido";
    },
    validaFecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida.";
      else
        return true;
    },

    validaUnicos: async function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
        await self.validaNombre().then(result => {}).catch(err => {
          duplicados.push("Nombre");
        });

        if (duplicados.length > 0)
          return reject(duplicados);
        else
          return resolve(true);

      });

    },

    validaNombre: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.nombre != null && self.model.nombre != "") {
          var busca = encodeURI(escape(self.model.nombre.toString().trim().toLowerCase()));
          window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/nombre?key=\"' + busca + '\"')
            .then(response => {

              if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
                if (self.model._id != "") { //Esta editando
                  var filter = response.data.rows.find(r => {
                    return self.model._id != r.id;
                  });
                  if (filter) {
                    return reject(false);
                  }
                } else { //Es nuevo
                  return reject(false);
                }
              }
              return resolve(true);
            })
            .catch(err => {
              return reject(false);
            });
        } else
          return resolve(true);
      });
    },

    getMarcas: function() {
      let data = {
        "selector": {
          "type": "marcas",
          "estatus": "Activo"
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.marcas = response.data.docs;
          else
            this.marcas = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las marcas/modelos.",
            footer: ""
          });
        });
    },
    getMarcasAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        let data = {
          "selector": {
            "type": "marcas",
            "estatus": "Activo",
          },
          "fields": ["nombre", "_id"]
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.marcas_all = response.data.docs;
            } else
              self.marcas_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });

    },
    getTiposPreciosAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        let data = {
          "selector": {
            "type": "configuracion"
          },
          "fields": ["tipos_precio"]
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.tipos_precios_all = response.data.docs[0].tipos_precio != undefined ? response.data.docs[0].tipos_precio : [];
            } else
              self.tipos_precios_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    getArticulosAll: function(opcion="") {
      var self = this;      
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {
            "type": "articulos"
          },
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {            
              self.articulos_all = [];            
            if (response.data.docs.length > 0) {
              self.articulos_all = response.data.docs                                      
            }
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    getArticulosExportarAll: function() {
      var self = this;
      
      window.dialogLoader.show('Espere un momento por favor..');      
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {
            "type": "articulos"
          },
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
      
            self.articulos_all = self.camposLayoutArticulos;                  
            if (response.data.docs.length > 0) {
              for(var k in response.data.docs){
                var art = response.data.docs[k];
                self.articulos_all.push({
                  codigo_barras: art["codigo_barras"][0],
                  nombre: art["nombre"],
                  descripcion: art["descripcion"],
                  categoria: art["categoria"].join(', '),
                  marca: art["marca"],
                  producto_servicio: art["producto_servicio"]["clave"],
                  unidad_medida: art["unidad_medida"]["clave"],
                  precio_compra: art["precio_compra"],
                  precio_compra_con_iva: art["precio_compra_con_iva"],
                  caducidad: art["caducidad"],
                  estatus: art["estatus"]
                })
              }                          
            }
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          }).then(()=>{
            
              var btn = document.getElementById('botonExportarArticulos');
              if(btn){
                btn.click();
              }
              window.dialogLoader.hide();
            
          });
      });
    },
    getImpuestosArticulosAll: function(){
      var self = this;      
        window.dialogLoader.show('Espere un momento por favor..');      
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {
            "type": "articulos"
          },
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            self.articulos_all = self.camposLayoutImpuestos;            
            if (response.data.docs.length > 0) {
              for(var k in response.data.docs){
                var art = response.data.docs[k];
                for(var ki in art["impuestos"]){
                  self.articulos_all.push({"codigo_barras": art["codigo_barras"][0], "nombre": art["nombre"],"impuesto":art["impuestos"][ki].impuesto, "tasa":art["impuestos"][ki].tasa});
                }
              }              
            }
              
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          }).then(()=>{
            
              var btn = document.getElementById('botonExportarImpuestos');
              if(btn){
                btn.click();
              }
              window.dialogLoader.hide();
            
          });
      });
    },
    getPreciosArticulosAll: function(){
      var self = this;      
        window.dialogLoader.show('Espere un momento por favor..');      
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {
            "type": "articulos"
          },
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            self.articulos_all = self.camposLayoutPreciosExportar;            
            if (response.data.docs.length > 0) {
              for(var k in response.data.docs){
                var art = response.data.docs[k];
                for(var ki in art["precios_venta"]){

                  self.articulos_all.push({"codigo_barras": art["codigo_barras"][0], 
                    "nombre":art["nombre"],
                    "tipo_precio":art["precios_venta"][ki].tipo_precio, 
                    "precio":art["precios_venta"][ki].precio, 
                    "precio_con_impuestos":art["precios_venta"][ki].precio_con_impuestos, 
                    "de":art["precios_venta"][ki].de, 
                    "a":art["precios_venta"][ki].a, });
                }
              }              
            }
              
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          }).then(()=>{
            
              var btn = document.getElementById('botonExportarPrecios');
              if(btn){
                btn.click();
              }
              window.dialogLoader.hide();
            
          });
      });
    },
    concatenedProducto: function(val) {
      return val.clave + " - " + val.nombre;
    },
    getProductosServicios: function() {
      let data = {
        "selector": {
          "type": "sat_productos_servicios",
          "estatus": "Activo"
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.productos = response.data.docs;
          else
            this.productos = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los productos/servicios del SAT.",
            footer: ""
          });
        });
    },
    getProductosServiciosAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        let data = {
          "selector": {
            "type": "sat_productos_servicios",
            "estatus": {
              "$eq": "Activo"
            },
          },
          "sort": [
            "clave"
          ],
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.productos_all = response.data.docs;
            } else
              self.productos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    getUnidadesMedida: function(el) {

      let data = {
        "selector": {
          "type": "sat_unidades_medida",
          "estatus": "Activo",
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.unidades = response.data.docs;
          else
            this.unidades = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las unidades de medida del SAT.",
            footer: ""
          });
        });
    },
    getUnidadesMedidaAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        let data = {
          "selector": {
            "type": "sat_unidades_medida",
            "estatus": {
              "$eq": "Activo"
            },
          },
          "sort": ["clave"],
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.unidades_all = response.data.docs;
            } else
              self.unidades_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });

    },
    getCategorias: function() {
      let data = {
        "selector": {
          "type": "categorias",
          "estatus": {
            "$eq": "Activo"
          }
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.sort(this.ordenar_nombre);
            this.categorias = response.data.docs;            
          } else
            this.categorias = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las categorias.",
            footer: ""
          });
        });
    },
    getDescripciones: function() {
      let data = {
        "selector": {
          "type": "descripciones",
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.sort(this.ordenar_descripcion);
            this.descripciones = response.data.docs;
            this.descripciones_all = this.descripciones;
            this.arr_descripciones = this.descripciones.map((item) => item.descripcion);
          } else{
            this.descripciones = [];
            this.descripciones_all = [];
          }
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las descripciones.",
            footer: ""
          });
          console.log(error);
        });
    },
    ordenar_nombre: function(a, b) {
      if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
        return -1;
      }
      if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    ordenar_descripcion: function(a, b) {
      if (a.descripcion.toLowerCase() < b.descripcion.toLowerCase()) {
        return -1;
      }
      if (a.descripcion.toLowerCase() > b.descripcion.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    getImpuestos: function() {
      let data = {
        "selector": {
          "type": "configuracion",
        },
        "fields": ["_id", "impuestos"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.impuestos = response.data.docs[0].impuestos != undefined ? response.data.docs[0].impuestos : [];
          else
            this.impuestos = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los impuestos.",
            footer: ""
          });
        });
    },
    getImpuestosAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {
        let data = {
          "selector": {
            "type": "configuracion",
          },
          "fields": ["_id", "impuestos"]
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.impuestos_all = response.data.docs[0].impuestos != undefined ? response.data.docs[0].impuestos : [];
            } else
              self.impuestos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });

    },
    validarRangoNoRepetido: function (rangoExterior, rangoInterior) {
        // Verifica que el rango exterior no esté dentro del rango interior
        if (rangoExterior[0] >= rangoInterior[0] && rangoExterior[1] <= rangoInterior[1]) {
            return false;
        }

        // Verifica que el rango interior no esté dentro del rango exterior
        if (rangoInterior[0] >= rangoExterior[0] && rangoInterior[1] <= rangoExterior[1]) {
            return false;
        }

        // Verifica que los rangos no se solapen
        if (rangoExterior[0] >= rangoInterior[0] && rangoExterior[0] <= rangoInterior[1]) {
            return false;
        }

        if (rangoExterior[1] >= rangoInterior[0] && rangoExterior[1] <= rangoInterior[1]) {
            return false;
        }

        return true;
    },
    validaRango: function(index){
        var record1 = this.model.precios_venta[index];
        for(var krango in this.model.precios_venta){
            if(krango != index){
                var record2 = this.model.precios_venta[krango];
                var rango1 = [parseFloat(record1.de), parseFloat(record1.a)];
                var rango2 = [parseFloat(record2.de), parseFloat(record2.a)];
                if (this.validarRangoNoRepetido(rango1, rango2)) {
                    //console.log('El rango exterior'+index+' no se repite dentro del rango interior'+krango);
                } else {
                    //console.log('El rango exterior'+index+' se repite dentro del rango interior'+krango);
                    return "El rango no debe repetirse o estar dentro de otro.";
                }
            }
        }
        return true;
    },
    getTiposPrecio: function() {
      let data = {
        "selector": {
          "type": "configuracion",
        },

        "fields": ["_id", "tipos_precio"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0){
            this.tipos_precios = response.data.docs[0].tipos_precio != undefined ? response.data.docs[0].tipos_precio : [];
            this.tipos_precios = this.tipos_precios.sort((a,b) => (a.orden < b.orden) ? -1 : (a.orden > b.orden) ? 1 : 0);
          }else
            this.tipos_precios = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los tipos de precios.",
            footer: ""
          });
        });
    },
    getArticulos: function() {
      // window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {
          "type": "articulos"
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos = response.data.docs;
            this.articulos.forEach(function(e) {
              e.tiene_impuestos = e.tiene_impuestos == 1 ? "Si" : "No";
              e.tiene_grupo = e.tiene_grupo == 1 ? "Si" : "No";
              e.tiene_informacion = e.tiene_informacion == 1 ? "Si" : "No";
            });
          } else
            this.articulos = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
        });
    },

    getRegistro: function(id_registro) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          try {
            this.model = response.data;
            this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
            if (this.model.informacion_nutrimental == null) {
              this.model.informacion_nutrimental = [];
              this.model.tiene_informacion = 0;
            }
            this.buscar_combos();
            this.filter_informacion_nutrimental();
            this.ver_existencias(id_registro);
            this.openModal(2);
          } catch (err) {
            console.log("ERROR: ", err);
          }

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },

    buscar_combos:function(){
      this.getImpuestoIVA();
      this.getCategorias();
      this.getDescripciones();
      this.getMarcas();
      this.getUnidadesMedida();
      this.getProductosServicios();
      this.getTiposPrecio();
      this.getImpuestos();
    },

    getData: function() {
      let usuario = this.$local_storage.get("sb_usuario");
      let data = {
        type: this.model.type,
        codigo_barras: this.model.codigo_barras,
        nombre: this.model.nombre.toString().trim(),
        created_at: this.model.created_at,
        estatus: this.model.estatus,
        descripcion: this.model.descripcion != null ? this.model.descripcion.toString().trim() : "",
        categoria: this.model.categoria != null ? this.model.categoria : [],
        caducidad: this.model.caducidad != null && this.model.caducidad != "" ? parseInt(this.model.caducidad) : 0,
        tiene_impuestos: this.model.tiene_impuestos != null && this.model.tiene_impuestos != "" ? parseInt(this.model.tiene_impuestos) : 0,
        tiene_grupo: this.model.tiene_grupo != null && this.model.tiene_grupo != "" ? parseInt(this.model.tiene_grupo) : 0,
        tiene_informacion: this.model.tiene_informacion != null && this.model.tiene_informacion != "" ? parseInt(this.model.tiene_informacion) : 0,
        precio_compra: this.model.precio_compra != null && this.model.precio_compra != "" ? parseFloat(this.model.precio_compra) : 0,
        precio_compra_con_iva: this.model.precio_compra_con_iva != null && this.model.precio_compra_con_iva != "" ? parseFloat(this.model.precio_compra_con_iva) : 0,
        marca: this.model.marca != null ? this.model.marca : "",

        producto_servicio: this.model.producto_servicio != null ? {
          "clave": this.model.producto_servicio.clave,
          "nombre": this.model.producto_servicio.nombre
        } : {},
        unidad_medida: this.model.unidad_medida != null ? {
          "clave": this.model.unidad_medida.clave,
          "nombre": this.model.unidad_medida.nombre
        } : {},

        precios_venta: this.model.precios_venta != null && this.model.precios_venta.length > 0 ? this.model.precios_venta : [],
        impuestos: this.model.impuestos != null && this.model.impuestos.length > 0 ? this.model.impuestos : [],
        grupo_articulos: this.model.grupo_articulos != null && this.model.grupo_articulos.length > 0 ? this.model.grupo_articulos : [],
        informacion_nutrimental: this.model.informacion_nutrimental != null && this.model.informacion_nutrimental.length > 0 ? this.model.informacion_nutrimental : [],
        equivalencias: this.model.equivalencias != null && this.model.equivalencias.length > 0 ? this.model.equivalencias : [],
        proveedores: this.model.proveedores != null && this.model.proveedores.length > 0 ? this.model.proveedores : [],
        stock: this.model.stock != null ? this.model.stock : {}
      };

      if (data["precios_venta"].length > 0) {
        data["precios_venta"].forEach(element => {
          element.precio = parseFloat(element.precio);
          element.precio_con_impuestos = parseFloat(element.precio_con_impuestos);
          element.porcentaje = parseFloat(element.porcentaje);
          element.de = parseFloat(element.de);
          element.a = parseFloat(element.a);
          element.usuario = usuario;
          element.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        });
      }

      if (data["impuestos"].length > 0) {
        data["impuestos"].forEach(element => {
          element.tasa = parseFloat(element.tasa);
          element.usuario = usuario;
          element.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        });
      }

      if (data["grupo_articulos"].length > 0) {
        data["grupo_articulos"].forEach(element => {
          element.cantidad = parseFloat(element.cantidad);
        });
      }

      return data;
    },
    saveRegistro: function() {
      if (this.$refs.form.validate() && this.$refs.formprecios.validate() &&
        ((this.$refs.formimpuestos != null && this.$refs.formimpuestos.validate()) || this.$refs.formimpuestos == null)
      ) {

        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {

          let data = this.getData();

          if (data["precios_venta"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un precio de venta. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          var flag_tipo = false;
          var flag_precio = false;
          var orden = [];
          if(data["precios_venta"].length > 1) {
            data["precios_venta"].forEach((it) => {
              let existe = this.tipos_precios.filter(x => x.nombre == it.tipo_precio);
              if(existe.length > 0) {
                existe = existe[0].orden;
                orden.push({"orden": existe, "de": it.de, "a": it.a, "precio": it.precio_con_impuestos})
              } else {
                console.log("ya no existe un tipo de precio");
              }
            });

            orden = orden.sort((a,b) => (a.orden < b.orden) ? -1 : (a.orden > b.orden) ? 1 : 0);
            let tmp=null;
            orden.forEach((or) => {
              if(tmp == null) {
                tmp = or.a;
              } else {
                if(tmp >= or.de) {
                  flag_tipo = true;
                } else {
                  tmp = or.a;
                }
              }
            });

            let tmp2=null;
            orden.forEach((or) => {
              if(tmp2 == null) {
                tmp2 = or.precio;
              } else {
                if(tmp2 < or.precio) {
                  console.log(tmp2, or.precio);
                  flag_precio = true;
                } else {
                  tmp2 = or.precio;
                }
              }
            });


          }
          if (flag_tipo) {
            window.dialogLoader.hide();
            this.$swal({
              icon: "info",
              title: "¡Datos Incompletos!",
              text: "Error en los tipos de precios. De o A no pueden ser iguales o menores a los tipos de precio con orden inferior.",
              footer: ""
            });
            return true;
          }
          if (flag_precio) {
            window.dialogLoader.hide();
            this.$swal({
              icon: "info",
              title: "¡Datos Incompletos!",
              text: "Error en los tipos de precios. El Precio de los tipos de precio no puede ser menor a un tipo de precio con orden mayor.",
              footer: ""
            });
            return true;
          }

          if (data["tiene_impuestos"] != 1)
            data["impuestos"] = [];
          else if (data["impuestos"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un impuesto. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          if (data["tiene_grupo"] != 1)
            data["grupo_articulos"] = [];
          else if (data["grupo_articulos"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un artículo al grupo. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          if (data["tiene_informacion"] != 1)
            data["informacion_nutrimental"] = [];
          else if (data["informacion_nutrimental"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un registro en información nutrimental. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          var pre_compara = parseFloat(data['precio_compra']);
          var flag = false;
          for (var i = 0; i < data['precios_venta'].length; i++) {
            if (pre_compara > parseFloat(data['precios_venta'][i].precio)) {
              flag = true;
              break;
            }
          }

          if (flag) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incorrectos!",
              text: "Los Precios de Venta no pueden ser menores al Precio de Compra. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {
              this.modal = false;
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });

        }).catch(err => {

          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },
    updateRegistro: function() {

      if (this.$refs.form.validate() && this.$refs.formprecios.validate() &&
        ((this.$refs.formimpuestos != null && this.$refs.formimpuestos.validate()) || this.$refs.formimpuestos == null)
      ) {

        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {

          let data = this.getData();
          data["_id"] = this.model._id;
          data["_rev"] = this.model._rev;

          if (data["precios_venta"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un precio de venta. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          var flag_tipo = false;
          var flag_precio = false;
          var orden = [];
          if(data["precios_venta"].length > 1) {
            data["precios_venta"].forEach((it) => {
              let existe = this.tipos_precios.filter(x => x.nombre == it.tipo_precio);
              if(existe.length > 0) {
                existe = existe[0].orden;
                orden.push({"orden": existe, "de": it.de, "a": it.a, "precio": it.precio_con_impuestos})
              } else {
                console.log("ya no existe un tipo de precio");
              }
            });

            orden = orden.sort((a,b) => (a.orden < b.orden) ? -1 : (a.orden > b.orden) ? 1 : 0);
            let tmp=null;
            orden.forEach((or) => {
              if(tmp == null) {
                tmp = or.a;
              } else {
                if(tmp >= or.de) {
                  flag_tipo = true;
                } else {
                  tmp = or.a;
                }
              }
            });

            let tmp2=null;
            orden.forEach((or) => {
              if(tmp2 == null) {
                tmp2 = or.precio;
              } else {
                if(tmp2 < or.precio) {
                  console.log(tmp2, or.precio);
                  flag_precio = true;
                } else {
                  tmp2 = or.precio;
                }
              }
            });


          }
          if (flag_tipo) {
            window.dialogLoader.hide();
            this.$swal({
              icon: "info",
              title: "¡Datos Incompletos!",
              text: "Error en los tipos de precios. De o A no pueden ser iguales o menores a los tipos de precio con orden inferior.",
              footer: ""
            });
            return true;
          }
          if (flag_precio) {
            window.dialogLoader.hide();
            this.$swal({
              icon: "info",
              title: "¡Datos Incompletos!",
              text: "Error en los tipos de precios. El Precio de los tipos de precio no puede ser menor a un tipo de precio con orden mayor.",
              footer: ""
            });
            return true;
          }


          if (data["tiene_impuestos"] != 1)
            data["impuestos"] = [];
          else if (data["impuestos"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un impuesto. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          if (data["tiene_grupo"] != 1)
            data["grupo_articulos"] = [];
          else if (data["grupo_articulos"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un artículo al grupo. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          if (data["tiene_informacion"] != 1)
            data["informacion_nutrimental"] = [];
          else if (data["informacion_nutrimental"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un registro en información nutrimental. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
              //actualizar equivalencia, por si cambio el nombre o e código de barras
              this.actualizar_equivalencia(this.model._id, data["nombre"], data["codigo_barras"]);
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
        }).catch(err => {
          console.log(err);
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },

    //Se actualiza el codigo y nombre del artículo en caso de que esté en una equivalencia de otro artículo
    actualizar_equivalencia: function(id_articulo, nombre, codigos){
      //Buscamos si está en una equivalencia
      var busca = encodeURI(escape(id_articulo));
      var self = this;
      var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_equivalentes?key=\"' + busca + '\"';
      axios.get(url)
        .then(response => {
          if (response.data.rows.length > 0) {

              response.data.rows.forEach((item) => {
                var art_origen = item.value["id_articulo"];
                if(item.value["nombre_articulo_equivalente"] != nombre || this.compara_arrays(item.value["codigo_articulo_equivalente"], codigos)==false){
                  //Si tiene el nombre o algun codigo diferente, se actualiza
                  window.axios
                  .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + art_origen)
                  .then(response => {                    
                      var articulo = response.data;
                      for(var k in articulo["equivalencias"]){
                        if(articulo["equivalencias"][k]["id_articulo_equivalente"] == id_articulo){
                          articulo["equivalencias"][k]["nombre_articulo_equivalente"] = nombre;
                          articulo["equivalencias"][k]["codigos_equivalente"] = codigos;
                        }
                      }

                      window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + articulo["_id"] + '?conflicts=true', articulo)
                      .then(response => {                        
                      })
                      .catch(error => {
                        console.log("Error al actualizar equivalente: ", error);
                      })

                      
                  })
                  .catch(error => {
                    console.log("Error al obtener articulo: ",error);
                  });
                }
              });
          }
        })
        .catch(error => {
          console.log(error)          
        })
    },

    compara_arrays: function(arr1, arr2){
      var result = true;
      if(arr1.length != arr2.length)//Si son de diferentes tamaños, se actualiza, sino se revisa item por item
        return false;
      for(var k in arr1){
        if(!arr2.includes(arr1[k])){
          result = false;
          break;
        }

      }      
      return result;
    },

    deleteRegistro: async function(_id, _rev) {
      window.dialogLoader.show('Espere un momento por favor..');
      let self = this;
      let data = {
        "selector": {
          "type": "ventas",
          "articulos": {
            "$elemMatch": {
              "_id": _id
            }
          }
        },
        "fields": ["_id"],
        //"use_index": "b8a585e5960e3dc7ff5750a94583c66cb3efa22e",
        "limit": 1
      };

      await window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_articulo?key=\"' + _id + '\"')
        .then(async response => {
          if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
            window.dialogLoader.showSnackbar('El producto ya tiene ventas imposible eliminarlo.', {
              color: 'error'
            });
          } else {
            let data = {
              "selector": {
                "type": "compras",
                "articulos": {
                  "$elemMatch": {
                    "id_articulo": _id
                  }
                }
              },
              "fields": ["_id"],
              "use_index": "b8a585e5960e3dc7ff5750a94583c66cb3efa22e",
              "limit": 1
            };

            await window.axios
              //.post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_articulo?key=\"' + _id + '\"')
              .then(async response => {
                if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
                  window.dialogLoader.showSnackbar('El producto ya tiene compras imposible eliminarlo.', {
                    color: 'error'
                  });
                } else {
                  this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "¿Está seguro de eliminar el registro?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar"
                  }).then(result => {
                    if (result.value) {
                      window.dialogLoader.show('Espere un momento por favor..');
                      window.axios
                      .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id)
                      .then(response => {
                        var revision = response.data["_rev"];
                        window.axios
                          .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + revision)
                          .then(response => {
                            window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                              color: 'success'
                            });
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            window.dialogLoader.hide();
                          })
                          .catch(error => {
                            window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                              color: 'error'
                            });
                            window.dialogLoader.hide();
                          });
                      }).catch(e =>{
                        window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                            color: 'error'
                          });
                          window.dialogLoader.hide();
                      });

                    }
                  });
                }
              })
              .catch(error => {
                console.log(error);
              });

          }
        })
        .catch(error => {
          console.log(error);
        });

      window.dialogLoader.hide();




    },
    beforeUpload(file) {
      var self = this;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (isLt2M) {
        self.file_adjunto = file;
        return true;
      }
      this.$swal({
        type: "warning",
        title: "¡Operación no Permitida!",
        text: "El archivo no debe ser mayor a 2MB.",
        footer: ""
      });
      self.file_adjunto = null;
      return false;
    },
    save_model_adjunto: function(tipo = "Artículos", errores = "") {
      window.dialogLoader.show('Espere un momento por favor..');
      //Creamos el registro de carga_inventario
      this.model_adjunto = {
        type: "articulos_layout",
        usuario: this.$local_storage.get("sb_usuario"),
        nombre_usuario: this.$local_storage.get("sb_nombre_usuario"),
        fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
        archivo: this.file_adjunto.name,
        tipo: tipo,
        estatus: errores != "" ? "Cargado Con Errores" : "Cargado Correctamente",
        errores: errores,
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model_adjunto)
        .then(response => {
          this.model_adjunto["_id"] = response.data.id;
          this.model_adjunto["_rev"] = response.data.rev;
          //Se adjunta el xls
          var name = encodeURIComponent(this.model_adjunto.archivo);
          var id_model = this.model_adjunto["_id"];
          var rev = this.model_adjunto["_rev"];

          var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + id_model + '/' + name;
          if (rev != "") { //Si trae rev es que ya se habían adjuntado archivos antes
            url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + id_model + '/' + name + "?rev=" + rev;
          }

          var self = this;
          window.axios
            .put(url, self.file_adjunto, {
              headers: {
                'Content-Type': self.file_adjunto.type
              }
            })
            .then(response => {})
            .catch(error => {
              window.axios
                .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_model + "?rev=" + rev)
                .then(response => {
                  this.registros.importaciones = [];
                  this.$refs.pagination_pw_importaciones.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
              window.dialogLoader.hide();
              console.log("ERROR ", error);
              self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al adjuntar el archivo. Intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              self.file_adjunto = null;
              window.dialogLoader.hide();
              this.registros.importaciones = [];
              this.$refs.pagination_pw_importaciones.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            });

        })
        .catch(error => {
          self.file_adjunto = null;
          window.dialogLoader.hide();
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al guardar el registro del layout.",
            footer: ""
          });
          this.registros.importaciones = [];
          this.$refs.pagination_pw_importaciones.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
    },
    descargarAdjunto: function(row) {
      var name = encodeURIComponent(row.archivo);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + row._id + '/' + name + "?rev=" + row._rev;
      a.target = "_blank";
      a.click();
      a.parentNode.removeChild(a);
    },
    eliminarAdjunto: function(row) {
      var _id = row._id; 
      var _rev = row._rev;
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.registros.importaciones = []; 
              this.$refs.pagination_pw_importaciones.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
            }).then(()=>{
              window.dialogLoader.hide();
            });

        }

      });
    },

    validaCargaLayoutArticulos: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var erroresText = '';
        var errorTmp = '';
        const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
        try {
          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 10000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 10 000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatoriosArticulos);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                //Si descripción viene null ponerla vacía
                if (results[k]['descripcion'] == null) {
                  results[k]['descripcion'] = "";
                } else {
                  results[k]['descripcion'] = results[k]['descripcion'].toString().trim().toUpperCase();
                  if (!self.arr_descripciones.includes(results[k]['descripcion'])) {
                    errores++;
                    errorTmp = '<br/>' + "- La descripción " + results[k]['descripcion'] + " no es una descripción válida.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  }
                }

                //validar precio sea float valido
                if (!patternFloat.test(results[k]['precio_compra'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El precio de compra " + results[k]['precio_compra'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                if (!patternFloat.test(results[k]['precio_compra_con_iva'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El precio de compra " + results[k]['precio_compra_con_iva'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                //Valida caducidad sea 1, sino ponerlo en cero
                if (results[k]['caducidad'] == null)
                  results[k]['caducidad'] = 0;
                else if (results[k]['caducidad'] != null && results[k]['caducidad'].toString().trim() == "1")
                  results[k]['caducidad'] = 1;
                else
                  results[k]['caducidad'] = 0;

                //validar estatus Activo,Inactivo
                if (!["Activo", "Inactivo"].includes(results[k]['estatus'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El estatus " + results[k]['estatus'] + " no es un estatus válido (Activo|Inactivo).";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }
                //validar categorias
                if (results[k]['categoria'] != null && results[k]['categoria'].toString().trim() != "") {
                  var categos = results[k]['categoria'].toString().trim().split(",");
                  var categosResult = [];
                  for (var kc in categos) {
                    var valCatego = self.validaCategoria(categos[kc].toString().trim());
                    if (valCatego == "") {
                      errores++;
                      errorTmp = '<br/>' + "- La categoría " + categos[kc] + " no se encontró en el catálogo.";
                      if (erroresText.indexOf(errorTmp) == -1)
                        erroresText += errorTmp;
                    } else
                      categosResult.push(valCatego);
                  }
                  results[k]['categoria'] = categosResult;
                }

                //validar marca
                if (results[k]['marca'] != null && results[k]['marca'].toString().trim() != "") {
                  var valMarca = self.validaMarca(results[k]['marca'].toString().trim());
                  if (valMarca == "") {
                    errores++;
                    errorTmp = '<br/>' + "- La marca " + results[k]['marca'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else
                    results[k]['marca'] = valMarca;
                }

                //validar producto/servicio SAT
                if (results[k]['producto_servicio'] != null && results[k]['producto_servicio'].toString().trim() != "") {
                  var valProducto = self.validaProducto(results[k]['producto_servicio'].toString().trim());
                  if (valProducto == "") {
                    errores++;
                    errorTmp = '<br/>' + "- La clave SAT de producto/servicio " + results[k]['producto_servicio'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else
                    results[k]['producto_servicio'] = {
                      "clave": valProducto.clave,
                      "nombre": valProducto.nombre
                    };
                }

                //validar unidad medida SAT
                if (results[k]['unidad_medida'] != null && results[k]['unidad_medida'].toString().trim() != "") {
                  var valUnidad = self.validaUnidad(results[k]['unidad_medida'].toString().trim());
                  if (valUnidad == "") {
                    errores++;
                    errorTmp = '<br/>' + "- La clave SAT de unidad " + results[k]['unidad_medida'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else
                    results[k]['unidad_medida'] = {
                      "clave": valUnidad.clave,
                      "nombre": valUnidad.nombre
                    };
                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccessArticulos: async function(
      {
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      try {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        self.getMarcasAll().then(result => {
          self.getProductosServiciosAll().then(result => {
            self.getUnidadesMedidaAll().then(result => {
              self.getArticulosAll().then(result => {
                self.validaCargaLayoutArticulos(results).then(validaciones => {
                  window.dialogLoader.show('Espere un momento por favor..');

                  if (validaciones.errores > 0) {
                    this.$swal({
                      type: "warning",
                      title: "¡Operación no Permitida!",
                      text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                      footer: validaciones.erroresText,
                    });
                    window.dialogLoader.hide();
                  } else {
                    for (var k in results) {
                      //Buscar codigo; si ya existe, traerse _id y _rev para editar
                      var codigo = results[k]['codigo_barras'].toString().trim().toUpperCase();
                      if (importData[codigo] != null) { //Si ya estaba en la colección (Significa que lo pusieron 2 o más veces en el excel) se actualiza, tomará los datos del último
                        importData[codigo].type = "articulos";
                        importData[codigo].nombre = results[k]['nombre'].toString().trim().toUpperCase();
                        importData[codigo].descripcion = results[k]['descripcion'].toString().trim().toUpperCase();
                        importData[codigo].categoria = results[k]['categoria'];
                        importData[codigo].marca = results[k]['marca'];
                        importData[codigo].producto_servicio = results[k]['producto_servicio'];
                        importData[codigo].unidad_medida = results[k]['unidad_medida'];
                        importData[codigo].precio_compra = parseFloat(results[k]['precio_compra']).toFixed(4);
                        importData[codigo].precio_compra_con_iva = parseFloat(results[k]['precio_compra_con_iva']).toFixed(4);
                        importData[codigo].caducidad = results[k]['caducidad'];
                        importData[codigo].estatus = results[k]['estatus'];
                      } else {
                        var articuloBuscado = self.validaArticuloImport(codigo);
                        if (articuloBuscado != "") { //Ya existe, hay que actualizar
                          importData[codigo] = articuloBuscado;
                          importData[codigo].nombre = results[k]['nombre'].toString().trim().toUpperCase();
                          //actualizamos equivalencia en caso de que tenga y que haya cambiado el nombre
                          self.actualizar_equivalencia(importData[codigo]._id, importData[codigo].nombre, importData[codigo].codigo_barras);  
                          importData[codigo].descripcion = results[k]['descripcion'].toString().trim().toUpperCase();
                          importData[codigo].categoria = results[k]['categoria'];
                          importData[codigo].marca = results[k]['marca'];
                          importData[codigo].producto_servicio = results[k]['producto_servicio'];
                          importData[codigo].unidad_medida = results[k]['unidad_medida'];
                          importData[codigo].precio_compra = parseFloat(results[k]['precio_compra']).toFixed(4);
                          importData[codigo].precio_compra_con_iva = parseFloat(results[k]['precio_compra_con_iva']).toFixed(4);
                          importData[codigo].caducidad = results[k]['caducidad'];
                          importData[codigo].estatus = results[k]['estatus'];
                        } else {
                          importData[codigo] = {};
                          importData[codigo].type = "articulos";
                          importData[codigo].codigo_barras = [codigo];
                          importData[codigo].nombre = results[k]['nombre'].toString().trim().toUpperCase();
                          importData[codigo].descripcion = results[k]['descripcion'].toString().trim().toUpperCase();
                          importData[codigo].categoria = results[k]['categoria'];
                          importData[codigo].marca = results[k]['marca'];
                          importData[codigo].producto_servicio = results[k]['producto_servicio'];
                          importData[codigo].unidad_medida = results[k]['unidad_medida'];
                          importData[codigo].precio_compra = parseFloat(results[k]['precio_compra']).toFixed(4);
                          importData[codigo].precio_compra_con_iva = parseFloat(results[k]['precio_compra_con_iva']).toFixed(4);
                          importData[codigo].caducidad = results[k]['caducidad'];
                          importData[codigo].estatus = results[k]['estatus'];
                          importData[codigo].created_at = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                          importData[codigo].tiene_impuestos = 0;
                          importData[codigo].tiene_grupo = 0;
                          importData[codigo].tiene_informacion = 0;
                          importData[codigo].precios_venta = [];
                          importData[codigo].impuestos = [];
                          importData[codigo].grupo_articulos = [];
                          importData[codigo].informacion_nutrimental = [];
                          importData[codigo].equivalencias = [];
                          importData[codigo].proveedores = [];

                        }
                      }

                    }

                    var bulkDatos = [];
                    for (var key in importData) {
                      bulkDatos.push(importData[key]);
                    }

                    var docs = {
                      "docs": bulkDatos
                    };
                    window.axios
                      .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                      .then(response => {
                        self.$swal({
                          type: "success",
                          title: "¡Operación Exitosa!",
                          text: "Los datos se guardaron correctamente.",
                          footer: "",
                        });
                        self.modalImportar = false;
                        window.dialogLoader.hide();
                        self.registros.items = [];
                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        self.save_model_adjunto("Artículos", "");

                      })
                      .catch(error => {
                        self.$swal({
                          type: "warning",
                          title: "¡Operación no Permitida!",
                          text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                          footer: "",
                        });
                        window.dialogLoader.hide();
                        self.save_model_adjunto("Artículos", error);
                      });

                  }
                }).catch(error => {
                  self.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo",
                    footer: error,
                  });
                  console.log(error);
                  window.dialogLoader.hide();

                });
              }).catch(error => {
                window.dialogLoader.hide();
                self.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener los artículos.",
                  footer: ""
                });
              });
            }).catch(error => {
              window.dialogLoader.hide();
              self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener las unidades de medida del SAT.",
                footer: ""
              });
            });
          }).catch(error => {
            window.dialogLoader.hide();
            self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los productos/servicios del SAT.",
              footer: ""
            });
          });
        }).catch(error => {
          window.dialogLoader.hide();
          self.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las marcas/modelos.",
            footer: ""
          });
        });


      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }
    },
    validaCargaLayoutPrecios: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var erroresText = '';
        var articulos = {};
        var precios = {};
        var errorTmp = '';
        let usuario = self.$local_storage.get("sb_usuario");
        const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
        const patternEntero = /^[\d]*$/;
        try {

          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 10000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 10 000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatoriosPrecios);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                //validar precio sea float valido
                if (!patternFloat.test(results[k]['precio'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El precio " + results[k]['precio'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }else if (parseFloat(results[k]['precio']) > 100000){
                  errores++;
                  errorTmp = '<br/>' + "- El precio " + results[k]['precio'] + " es demasiado grande. Verifique.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                if (!patternFloat.test(results[k]['de'])) {
                  errores++;
                  errorTmp = '<br/>' + "- La cantidad inicial " + results[k]['de'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                if (!patternFloat.test(results[k]['a'])) {
                  errores++;
                  errorTmp = '<br/>' + "- La cantidad final " + results[k]['a'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                //validar tipo de precio
                if (results[k]['tipo_precio'] != null && results[k]['tipo_precio'].toString().trim() != "") {
                  var valTipo = self.validaTipoPrecioImport(results[k]['tipo_precio'].toString().trim());
                  if (valTipo == "") {
                    errores++;
                    errorTmp = '<br/>' + "- El tipo precio " + results[k]['tipo_precio'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else
                    results[k]['tipo_precio'] = valTipo;
                }

                //Valida qué exista el codigo_barras
                if (results[k]['codigo_barras'] != null && results[k]['codigo_barras'].toString().trim() != "") {
                  var valArticulo = self.validaArticuloImport(results[k]['codigo_barras']);
                  if (valArticulo == "") {
                    errores++;
                    errorTmp = '<br/>' + "- El código de barras " + results[k]['codigo_barras'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else {
                      articulos[results[k]['codigo_barras']] = valArticulo;
                      if (precios[results[k]['codigo_barras']] != null) { //Si ya estaba en la colección (Significa que lo pusieron 2 o más veces en el excel) se actualiza, tomará los datos del último
                          precios[results[k]['codigo_barras']][results[k]['tipo_precio']] = {};                       
                      } else {
                          precios[results[k]['codigo_barras']] = {};
                      }

                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']] = {};
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].tipo_precio = results[k]['tipo_precio'];
                      //precios[results[k]['codigo_barras']][results[k]['tipo_precio']].precio = parseFloat(parseFloat(results[k]['precio']).toFixed(2));
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].precio_con_impuestos = parseFloat(parseFloat(results[k]['precio']).toFixed(2));
                      var impuestos = 0;
                      for (var ki in valArticulo["impuestos"]) {
                        impuestos += parseFloat(valArticulo["impuestos"][ki]["tasa"]);
                      }
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']]["precio"] = precios[results[k]['codigo_barras']][results[k]['tipo_precio']].precio_con_impuestos / (1 + (parseFloat(impuestos) / 100));
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']]["precio"] = parseFloat(precios[results[k]['codigo_barras']][results[k]['tipo_precio']]["precio"].toFixed(2));
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].porcentaje = parseFloat(0);//Cuando se carguen precios siempre hay que dejarlo en cero
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].de = parseFloat(results[k]['de']);
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].a = parseFloat(results[k]['a']);
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].usuario = usuario;
                      precios[results[k]['codigo_barras']][results[k]['tipo_precio']].fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");

                  }

                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText,
            "articulos": articulos,
            "precios": precios,
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccessPrecios: async function({
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      try {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        self.getTiposPreciosAll().then(result => {
          self.getArticulosAll().then(result => {
            self.validaCargaLayoutPrecios(results).then(validaciones => {
              window.dialogLoader.show('Espere un momento por favor..');

              if (validaciones.errores > 0) {
                this.$swal({
                  type: "warning",
                  title: "¡Operación no Permitida!",
                  text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                  footer: validaciones.erroresText,
                });
                window.dialogLoader.hide();
              } else {
                window.dialogLoader.show('Espere un momento por favor..');
                //console.log("PRECIOS: ",validaciones.precios);
                for (var codigok in validaciones.precios) {
                  for (var tipok in validaciones.precios[codigok]) {
                    if (validaciones.articulos[codigok]["precios_venta"].length > 0) {
                      var encontrado = false;
                      //Buscar si el tipo esta en el artículo, si si esta reemplazarlo y si no está agregarlo
                      for (var preciok in validaciones.articulos[codigok]["precios_venta"]) {
                          if (validaciones.articulos[codigok]["precios_venta"][preciok].tipo_precio == tipok) {
                              encontrado = true;
                              //Si el articulo ya tenía un porcentaje de aumento, se deja en cero y se respeta el precio que viene en la plantilla
                              /*try {
                                  validaciones.precios[codigok][tipok]["precio"] = validaciones.precios[codigok][tipok]["precio"];
                                  validaciones.precios[codigok][tipok]["precio"] = parseFloat(validaciones.precios[codigok][tipok]["precio"].toFixed(2));
                                  var impuestos = 0;
                                  for (var k in validaciones.articulos[codigok]["impuestos"]) {
                                    impuestos += parseFloat(validaciones.articulos[codigok]["impuestos"][k]["tasa"]);
                                  }
                                  validaciones.precios[codigok][tipok]["precio_con_impuestos"] = validaciones.precios[codigok][tipok]["precio"] * (1 + (parseFloat(impuestos) / 100));
                                  validaciones.precios[codigok][tipok]["precio_con_impuestos"] = parseFloat(validaciones.precios[codigok][tipok]["precio_con_impuestos"].toFixed(2));
                              } catch (error) {
                                //No tiene porcentaje, se queda con el precio que den de alta
                              }*/

                              validaciones.articulos[codigok]["precios_venta"][preciok] = validaciones.precios[codigok][tipok];
                              break;
                          }
                      }

                      if (encontrado == false) {
                        validaciones.articulos[codigok]["precios_venta"].push(validaciones.precios[codigok][tipok]);
                      }
                    } else {
                      validaciones.articulos[codigok]["precios_venta"].push(validaciones.precios[codigok][tipok]);
                    }

                  }
                }

                var bulkDatos = [];
                for (var key in validaciones.articulos) {
                  bulkDatos.push(validaciones.articulos[key]);
                }

                var docs = {
                  "docs": bulkDatos
                };
                window.axios
                  .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                  .then(response => {
                    self.$swal({
                      type: "success",
                      title: "¡Operación Exitosa!",
                      text: "Los precios se guardaron correctamente.",
                      footer: "",
                    });
                    self.modalImportar = false;
                    window.dialogLoader.hide();
                    self.registros.items = [];
                    self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    self.save_model_adjunto("Precios de Artículos", "");
                  })
                  .catch(error => {
                    self.$swal({
                      type: "warning",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                      footer: "",
                    });
                    window.dialogLoader.hide();
                    self.save_model_adjunto("Precios de Artículos", error);
                  });

              }
            }).catch(error => {
              self.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "Error al cargar el archivo",
                footer: error,
              });
              console.log(error);
              window.dialogLoader.hide();

            });
          }).catch(error => {
            window.dialogLoader.hide();
            self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los artículos.",
              footer: ""
            });
          });
        }).catch(error => {
          window.dialogLoader.hide();
          self.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los tipos de precios.",
            footer: ""
          });
        });

      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }

    },
    validaCargaLayoutImpuestos: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var erroresText = '';
        var articulos = {};
        var impuestos = {};
        var errorTmp = '';
        let usuario = self.$local_storage.get("sb_usuario");
        try {

          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 20000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 20000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatoriosImpuestos);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                //validar tipo de impuesto
                if (results[k]['impuesto'] != null && results[k]['impuesto'].toString().trim() != "") {
                  var valTipo = self.validaTipoImpuestoImport(results[k]['impuesto'].toString().trim());
                  if (valTipo == "") {
                    errores++;
                    errorTmp = '<br/>' + "- El tipo de impuesto " + results[k]['impuesto'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else
                    results[k]['impuesto'] = valTipo;
                }

                //Valida qué exista el codigo_barras
                if (results[k]['codigo_barras'] != null && results[k]['codigo_barras'].toString().trim() != "") {
                  var valArticulo = self.validaArticuloImport(results[k]['codigo_barras']);
                  if (valArticulo == "") {
                    errores++;
                    errorTmp = '<br/>' + "- El código de barras " + results[k]['codigo_barras'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else {
                    articulos[results[k]['codigo_barras']] = valArticulo;
                    articulos[results[k]['codigo_barras']].tiene_impuestos = 1;
                    if (impuestos[results[k]['codigo_barras']] != null) { //Si ya estaba en la colección (Significa que lo pusieron 2 o más veces en el excel) se actualiza, tomará los datos del último
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre] = {}
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].impuesto = results[k]['impuesto'].nombre;
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].tasa = parseFloat(results[k]['impuesto'].tasa);
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].clave = results[k]['impuesto'].clave;
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].usuario = usuario;
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                      impuestos[results[k]['codigo_barras']] = {}
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre] = {}
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].impuesto = results[k]['impuesto'].nombre;
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].tasa = parseFloat(results[k]['impuesto'].tasa);
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].clave = results[k]['impuesto'].clave;
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].usuario = usuario;
                      impuestos[results[k]['codigo_barras']][results[k]['impuesto'].nombre].fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                    }
                  }

                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText,
            "articulos": articulos,
            "impuestos": impuestos,
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccessImpuestos: async function({
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      try {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        self.getImpuestosAll().then(result => {
          self.getArticulosAll().then(result => {
            self.validaCargaLayoutImpuestos(results).then(validaciones => {
              window.dialogLoader.show('Espere un momento por favor..');

              if (validaciones.errores > 0) {
                this.$swal({
                  type: "warning",
                  title: "¡Operación no Permitida!",
                  text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                  footer: validaciones.erroresText,
                });
                window.dialogLoader.hide();
              } else {
                //console.log("Impuestos: ", validaciones.impuestos);
                for (var codigok in validaciones.impuestos) {
                  validaciones.articulos[codigok]["impuestos"] = []; //Limpiamos impuestos para que guarde solo los de la plantilla
                  for (var tipok in validaciones.impuestos[codigok]) {                    

                    if (validaciones.articulos[codigok]["impuestos"].length > 0) {
                      var encontrado = false;
                      //Buscar si el tipo esta en el artículo, si si esta reemplazarlo y si no está agregarlo
                      for (var impuestok in validaciones.articulos[codigok]["impuestos"]) {

                        if (validaciones.articulos[codigok]["impuestos"][impuestok].impuesto == tipok) {
                          encontrado = true;
                          validaciones.articulos[codigok]["impuestos"][impuestok] = validaciones.impuestos[codigok][tipok];
                          break;
                        }
                      }

                      if (encontrado == false) {
                        validaciones.articulos[codigok]["impuestos"].push(validaciones.impuestos[codigok][tipok]);
                      }

                    } else {
                      validaciones.articulos[codigok]["impuestos"].push(validaciones.impuestos[codigok][tipok]);
                    }

                  }

                  //Calcular el precio con impuestos de cada tipo de precio del articulo
                  //console.log("Articulo: ", validaciones.articulos[codigok]);
                  var impuestos_articulo = 0;
                  for (var k in validaciones.articulos[codigok]["impuestos"]) {
                    impuestos_articulo += parseFloat(validaciones.articulos[codigok]["impuestos"][k]["tasa"]);
                  }

                  for (var preciok in validaciones.articulos[codigok]["precios_venta"]) {
                    try {
                      validaciones.articulos[codigok]["precios_venta"][preciok]["precio_con_impuestos"] = parseFloat(validaciones.articulos[codigok]["precios_venta"][preciok]["precio"]) * (1 + (parseFloat(impuestos_articulo) / 100));
                      validaciones.articulos[codigok]["precios_venta"][preciok]["precio_con_impuestos"] = parseFloat(validaciones.articulos[codigok]["precios_venta"][preciok]["precio_con_impuestos"].toFixed(2));
                    } catch (error) {
                      //No tiene porcentaje, se queda con el precio que den de alta
                    }
                  }

                }

                var bulkDatos = [];
                for (var key in validaciones.articulos) {
                  bulkDatos.push(validaciones.articulos[key]);
                }

                var docs = {
                  "docs": bulkDatos
                };
                window.axios
                  .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                  .then(response => {
                    self.$swal({
                      type: "success",
                      title: "¡Operación Exitosa!",
                      text: "Los impuestos se guardaron correctamente.",
                      footer: "",
                    });
                    self.modalImportar = false;
                    window.dialogLoader.hide();
                    self.registros.items = [];
                    self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    self.save_model_adjunto("Impuestos de Artículos", "");
                  })
                  .catch(error => {
                    self.$swal({
                      type: "warning",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                      footer: "",
                    });
                    window.dialogLoader.hide();
                    self.save_model_adjunto("Impuestos de Artículos", error);
                  });

              }
            }).catch(error => {
              self.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "Error al cargar el archivo",
                footer: error,
              });
              console.log(error);
              window.dialogLoader.hide();

            });
          }).catch(error => {
            window.dialogLoader.hide();
            self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los artículos.",
              footer: ""
            });
          });
        }).catch(error => {
          window.dialogLoader.hide();
          self.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los tipos de impuestos.",
            footer: ""
          });
        });

      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }

    },
    verificaObligatorios: function(results, camposObligatorios) {
      var errores = '';
      var keys = [];
      for (var k in results) {
        keys.push(k);
      }

      camposObligatorios.forEach(function(campo) {
        if (keys.indexOf(campo) == -1)
          errores += '<br/>' + "- El campo '" + campo + "' es obligatorio.";
      });

      return errores == '' ? true : errores;
    },
    validaCategoria: function(val) {
      var filter = this.categorias.find(e => e.nombre.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter.nombre;
      else {
        var catego = val.toString().toUpperCase().trim();
        //Ingresar a catalogo

        let data = {
          type: "categorias",
          descripcion: catego,
          nombre: catego,
          estatus: "Activo",
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
          .then(response => {});
        this.categorias.push({
          "nombre": catego
        });
        return catego;
      }
    },
    validaTipoPrecioImport: function(val) {
      var filter = this.tipos_precios_all.find(e => e.nombre.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter.nombre;
      else
        return "";
    },
    validaTipoImpuestoImport: function(val) {
      var filter = this.impuestos_all.find(e => e.nombre.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter;
      else
        return "";
    },
    validaArticuloImport: function(val) {
      var filter = this.articulos_all.find(function(e) {
        return e.codigo_barras.includes(val.toString().toUpperCase().trim());
      });
      
      if (filter)
        return filter;
      else
        return "";
    },
    validaMarca: function(val) {
      var self = this;
      var filter = self.marcas_all.find(e => e.nombre.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter.nombre;
      else {
        var marca = val.toString().toUpperCase().trim();
        //Ingresar a catalogo
        let data = {
          type: "marcas",
          descripcion: marca,
          nombre: marca,
          estatus: "Activo",
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
          .then(response => {});
        self.marcas_all.push({
          "nombre": marca
        });
        return marca;
      }

    },
    validaProducto: function(val) {
      var filter = this.productos_all.find(e => e.clave.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter;
      else
        return "";
    },
    validaUnidad: function(val) {
      var filter = this.unidades_all.find(e => e.clave.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter;
      else
        return "";
    },
    agregar_codigo: function() {
      if (this.$refs.form_codigo.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        let data = {
          "selector": {
            "codigo_barras": {
              "$in": [this.codigo_nuevo.toString()]
            },
            "type": "articulos"
          },
          "limit": 2,
          "fields": ["_id"]
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              window.dialogLoader.hide();
              this.$swal({
                type: "info",
                title: "¡Operación no Permitida!",
                text: "El Código de Barras ya existe en otro Artículo",
                footer: ""
              });
            } else {
              window.dialogLoader.hide();
              this.model.codigo_barras.push(this.codigo_nuevo)
              this.modalCodigo = false;
            }
          })
          .catch(error => {
            window.dialogLoader.hide();
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al validar el código.",
              footer: ""
            });
          });
      }
    },
    open_componente: function() {
      this.props_historico.sucursales = window.sucursales;
      this.props_historico.modal = true;
    },
    getRegistroImagen: function(id_registro) {
      this.file_adjunto = null;
      this.imagen_adjunto = "";
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          try {
            this.model = response.data;
            //CONSULTA: traer archivos adjuntos
            if(response.data._attachments != null){
                for(var key in response.data._attachments){
                    //console.log(key);
                    var name = encodeURIComponent(key);
                    this.imagen_adjunto = {
                      "nombre":key,
                      "_id":response.data._id,
                      "_rev":response.data._rev,
                      "source": process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +this.model._id+'/'+name+"?rev="+this.model._rev};
                }
            }
          } catch (err) {
            console.log("ERROR: ", err);
          }

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        }).then(()=>{
          this.modalImagen=true;
        });
    },
    adjuntarImagen: function(){

        if(this.file_adjunto!=null){
          window.dialogLoader.show('Espere un momento por favor..');

          var name = encodeURIComponent(this.file_adjunto.name);
          var id_model = this.model._id;
          var rev = this.model._rev;

          var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +id_model+'/' + name;
          if(rev != ""){ //Si trae rev es que ya se habían adjuntado archivos antes
              url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +id_model+'/' + name+"?rev="+rev;
          }

          var self = this;
          window.axios
          .put(url, self.file_adjunto, {headers:{'Content-Type':self.file_adjunto.type}})
          .then(response => {
              window.dialogLoader.showSnackbar('Archivo guardado correctamente', {
                  color: 'success'
              });
          })
          .catch(error => {
              window.dialogLoader.hide();
              console.log("ERROR ", error);
              self.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al adjuntar el archivo. Intente nuevamente.",
                  footer: ""
              });
          }).then(()=>{
              self.file_adjunto = null;
              window.dialogLoader.hide();
              this.modalImagen = false;
              this.getRegistroImagen(id_model);
          });
        }

    },
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            numero = 0;
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    descargarImagen: function(row){
        var name = encodeURIComponent(row.nombre);

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +row._id+'/'+name+"?rev="+row._rev;
        a.target = "_blank";
        a.click();
        a.parentNode.removeChild(a);
    },
    eliminarImagen: function(row){
        window.dialogLoader.show('Espere un momento por favor..');
        var name = encodeURIComponent(row.nombre);
        var self = this;

        window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +row._id+'/'+name+"?rev="+row._rev
            ).then(response => {
                window.dialogLoader.showSnackbar('Archivo eliminado correctamente', {
                    color: 'success'
                });
            })
            .catch(error => {
                self.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al eliminar el archivo. Intente nuevamente.",
                        footer: ""
                    });
            }).then(()=>{
                window.dialogLoader.hide();
                this.modalImagen = false;
                this.getRegistroImagen(row._id);
            });
    },
    selectRow(row) {          
      this.selectedRowIndex = row.row._id;          
    },
    getRowClass(row) {          
      return this.selectedRowIndex === row._id ? 'selected-row' : '';
    }

  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: black;
  font-size: small;
  background-color: #EEEEEE;
  padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: white;
  font-size: small;
  background-color: #df7205;
  padding: 0px;
  font-weight: bold;

}

.v-tabs-slider-wrapper {
  left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: x-small !important;
}
.v-card__text >>> #datagrid td {
    font-weight:bolder;
    font-size: 11px; 
}
.elevation-2 >>> .VueTables__row.selected-row {
  background-color: #d3f2dc !important;
}
</style>
