<template>
<v-dialog v-model="props.modal" max-width="70%" persistent>
  <v-card>
    <v-card-title>
      <v-card class="primary white--text titulomodal">
        <v-icon class="white--text">info</v-icon>&nbsp;
        <span class="subheading">
          <strong>HISTORICO DEL PRODUCTO</strong>
        </span>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="cerrar" class="close_modal">
        <v-icon class="white--text">cancel</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>

      <br>
      <v-container>
        <v-row>
          <v-tabs grow style="margin-bottom: 30px;" v-model="model.tipo">
            <v-tab :key="1">Ventas</v-tab>
            <v-tab-item :key="1">
              <v-form ref="form" lazy-validation>
                <div class="form_ventas">
                  <v-container grid-list-md @keyup.enter="calcular()">
                    <v-row>
                      <v-col sm="12" md="6" lg="6">
                        <v-menu offset-x ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de_venta" label="Mes Inicial" color="secondary" prepend-icon="event" @blur="model.fecha_de_venta = parse_date(model.fecha_de_venta)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                          </template>

                          <v-date-picker v-model="model.fecha_de_venta" color="secondary" scrollable locale="es-mx" @input="menu3 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu3 = false">
                              Cancelar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col sm="12" md="6" lg="6">
                        <v-menu offset-x ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_a_venta" label="Mes Final" color="secondary" prepend-icon="event" @blur="model.fecha_a_venta = parse_date(model.fecha_a_venta)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                          </template>

                          <v-date-picker v-model="model.fecha_a_venta" color="secondary" scrollable locale="es-mx" @input="menu4 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu4 = false">
                              Cancelar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" md="3" lg="3">
                        <v-radio-group v-model="model.periodo" row :rules="[rules.required]">
                          <template v-slot:label>
                            <div>Periodo</div>
                          </template>
                          <!--v-radio label="Día" value="1"></v-radio-->
                          <v-radio label="Mes" value="2"></v-radio>
                          <v-radio label="Año" value="3"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col sm="12" md="3" lg="3">
                        <v-autocomplete v-model="model.id_sucursal" :items="props.sucursales" :hide-no-data="true" :hide-selected="true" item-text="nombre" return-object label="Sucursal" :rules="[requiredObject]">
                        </v-autocomplete>
                      </v-col>
                      <v-col sm="12" md="3" lg="3">
                        <v-text-field label="Días Plazo de Calculo" v-model="model.plazo" :rules="[rules.required]"></v-text-field>
                      </v-col>
                      <v-col sm="12" md="3" lg="3" style="text-align:right">
                        <v-btn small color="success" @click.native="calcular()">
                          <v-icon>done</v-icon> Calcular
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>

              </v-form>
              <v-client-table ref="vuetable_ventas" :data="items" :columns="table.columns" :options="table.options">
                <template slot="total_venta" slot-scope="props">
                  <div style="text-align:center">{{formatNumberDec(props.row.total_venta,2)}}</div>
                </template>
                <template slot="promedio_venta" slot-scope="props">
                  <div style="text-align:center">{{formatNumberDec(props.row.promedio_venta,2)}}</div>
                </template>
                <template slot="precio_promedio_venta" slot-scope="props">
                  <div style="text-align:right">${{formatNumberDec(props.row.precio_promedio_venta,2)}}</div>
                </template>
                <template slot="minimo" slot-scope="props">
                  <div style="text-align:center">{{props.row.minimo}}</div>
                </template>
                <template slot="maximo" slot-scope="props">
                  <div style="text-align:center">{{props.row.maximo}}</div>
                </template>
                <template slot="reorden" slot-scope="props">
                  <div style="text-align:center">{{props.row.reorden}}</div>
                </template>
                
                <template slot="precio" slot-scope="props">
                  <div style="text-align:right">${{props.row.precio.toFixed(2)}}</div>
                </template>
              </v-client-table>
              <v-row>
                <v-spacer></v-spacer>
                <v-col sm="4" style="text-align:right">
                  <v-btn color="warning" @click.native="guardar">
                    <v-icon>save</v-icon> Guardar Cambios
                  </v-btn>
                </v-col>
              </v-row>

            </v-tab-item>
          </v-tabs>

        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "HistoricoMasivoComponent",
  props: ['props'],

  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      model: {
        fecha_de: "",
        fecha_a: "",
        periodo: "2",
        tipo: 1,
        fecha_de_venta: "",
        fecha_a_venta: "",
        plazo: 15,
        id_sucursal: ""
      },
      table: {
        columns: [
          "articulo",
          "total_venta",
          "promedio_venta",
          "precio_promedio_venta",
          "minimo",
          "maximo",
          "reorden",
        ],
        options: {
          perPage: 10,
          pagination: {
            show: true
          },
          filterable: ["articulo", "total_venta", "promedio_venta", "precio_promedio_venta", "minimo", "maximo", "reorden"],
          headings:{
            "promedio_venta":"Promedio Venta Diaria"
          },
        }
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
      },
      items: [],
      // total_venta: 0,
      // promedio_venta: 0,
      // precio_promedio_venta: 0,
      // minimo: 0,
      // reorden: 0,
      // maximo: 0,
      // cantidad_pedir: 0,
      // consumo_minimo: 0,
      // consumo_medio: 0,
      // consumo_maximo: 0,
      // existencia: 0,
      articulo: {},
      articulos: [],
      existencias: []
    }
  },
  methods: {
   
    calcular: async function() {
      if (this.$refs.form.validate()) {
        this.items = [];
        window.dialogLoader.show('Espere un momento por favor..');
        this.model.tipo = 1;

        let plazo = this.model.plazo;

        // await this.get_existencia();
        await this.get_articulos();
        console.log("Articulos: ", this.articulos.length);
        const [year_de, month_de, day_de] = this.model.fecha_de_venta.split("-");
        var [year_a, month_a, day_a] = this.model.fecha_a_venta.split("-");
        if( parseInt(month_a) == 12){ //Se agrega un mes mas, porque como agrupa por mes en el endkey ignora el mes final, por eso se agrega 1 para que lo tome en cuenta
          year_a = (parseInt(year_a) + 1).toString();
          month_a = "01"
        } else {
          month_a = parseInt(month_a) + 1;
          if (month_a < 10)
              month_a = '0' + month_a.toString();

        }
        let group_level = "4"; //4 Cuando se busque por sucursal, 3 cuando no
        var url = "";
        var idx_anio = 0;
        var idx_mes = 1;            
        var idx_sucursal = 2;
        var idx_articulo = 3;
        if (this.model.id_sucursal._id != "0") { //Si trae sucursal se filtran las ventas de esa sucursal
          url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo_sucursal?group_level=' + group_level;
          url += `&startkey=["${this.model.id_sucursal._id}", "${year_de}", "${month_de}", "{}"]&endkey=["${this.model.id_sucursal._id}", "${year_a}", "${month_a}", "{}"]`;
        } else {//Si no trae sucursal, se filtran solo por mes y año
          idx_anio = 0;
          idx_mes = 1;            
          idx_articulo = 2;
          group_level = "3";
          url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo_mes?group_level=' + group_level;
          url += `&startkey=["${year_de}", "${month_de}", "{}"]&endkey=["${year_a}", "${month_a}", "{}"]`;
        }
        
        axios.get(url)
          .then(response => {

                                    

            if (response.data.rows.length > 0) {
              let respuesta = response.data.rows;
              console.log("Respuesta: ", respuesta.length);
              this.articulos.forEach((item) => {
                
                let procesado = [];
                let datos_articulo = {};
                let item_ventas = respuesta.filter(x => x.key[idx_articulo] == item._id);

                //Aqui ya seria agrupar por dia, semana o mes
                if (this.model.periodo == "1") { //SE DESHABILITO POR DIA
                  console.log("busqueda por dias")
                  item_ventas.forEach(x => {
                    let key = `${x.key[idx_anio]}-${x.key[idx_mes]}-${x.key[idx_dia]}`;                    
                      console.log("agregando dias")
                      procesado.push({
                        "fecha": key,
                        "cantidad": x.value.cantidad,
                        "precio": x.value.precio_venta / x.value.cantidad,
                        "total": 0
                      });
                    
                  });
                } else if (this.model.periodo == "2") { //if si es por mes

                  console.log("busqueda por mes")
                  item_ventas.forEach(x => {
                    let key = `${x.key[idx_anio]}-${x.key[idx_mes]}`;                    
                      procesado.push({
                        "fecha": key,
                        "cantidad": x.value.cantidad,
                        "precio": x.value.precio_venta / x.value.cantidad,
                        "total": 0
                      });
                    
                  });

                } else { // if si es por año
                  console.log("busqueda por año")
                  item_ventas.forEach(x => {
                    let key = `${x.key[idx_anio]}`;
                    if (item._id == "4fc280aaceffa5bf2a6516be1a659c64")
                          console.log("KEY: ", key)
                      var encontrado = procesado.find( e => e.fecha == key);
                      if (encontrado){
                        for(var k in procesado){
                          if (item._id == "4fc280aaceffa5bf2a6516be1a659c64")
                            console.log("Si entra: ")
                          if(procesado[k]["fecha"] == key){                            
                            procesado[k]["cantidad"] += x.value.cantidad;
                            procesado[k]["precio"] += (x.value.precio_venta / x.value.cantidad);
                          } 
                        }
                      } else {                        
                          procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                          });
                      }                      
                                                                  
                  });
                  if (item._id == "4fc280aaceffa5bf2a6516be1a659c64")
                    console.log("Prcesado",procesado);
                }                

                //llenar los datos de ventas
                datos_articulo.total_venta = 0;
                datos_articulo.promedio_venta = 0;
                datos_articulo.precio_promedio_venta = 0;

                let sorted = []
                procesado.forEach(x => {
                  sorted.push(x);
                  datos_articulo.total_venta += x.cantidad;
                  datos_articulo.promedio_venta += x.cantidad;
                  datos_articulo.precio_promedio_venta += x.precio;
                });

                let numero_dias_tiempo = new Date(this.model.fecha_a_venta) - new Date(this.model.fecha_de_venta);
                let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);
                if(numero_dias <= 0)
                    numero_dias = 1;
                numero_dias += 1; //Se suma 1, por el día que se pierde en la resta

                datos_articulo.promedio_venta = datos_articulo.promedio_venta > 0 ? Math.ceil(datos_articulo.promedio_venta / numero_dias) : 0;
                datos_articulo.precio_promedio_venta = datos_articulo.precio_promedio_venta > 0 ? datos_articulo.precio_promedio_venta / procesado.length : 0;
                
                //Falta sacar los datos del articulo maximo, minimo, etc
                if (sorted.length > 0) {
                  sorted.sort((a, b) => {
                    return a.cantidad - b.cantidad;
                  });

                  datos_articulo.consumo_minimo = this.model.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (this.model.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));                  
                  datos_articulo.consumo_maximo = this.model.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (this.model.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length - 1].cantidad / 365));                  
                  datos_articulo.consumo_medio = datos_articulo.promedio_venta;

                  datos_articulo.minimo = datos_articulo.consumo_minimo * plazo; 
                  datos_articulo.reorden = (datos_articulo.consumo_medio * plazo) + datos_articulo.minimo;
                  datos_articulo.maximo = (datos_articulo.consumo_maximo * plazo) + datos_articulo.minimo;
                  datos_articulo.articulo = item.nombre;
                  datos_articulo.id_articulo = item._id;

                } else {
                  sorted = [{
                    "fecha": "0000-00-00",
                    "cantidad": 0,
                    "precio": 0,
                    "total": 0
                  }];

                  datos_articulo.consumo_minimo = this.model.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (this.model.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));
                  datos_articulo.consumo_maximo = this.model.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (this.model.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length -
                      1]
                    .cantidad / 365));
                  datos_articulo.consumo_medio = datos_articulo.promedio_venta;

                  datos_articulo.minimo = datos_articulo.consumo_minimo * plazo;
                  datos_articulo.reorden = (datos_articulo.consumo_medio * plazo) + datos_articulo.minimo;
                  datos_articulo.maximo = (datos_articulo.consumo_maximo * plazo) + datos_articulo.minimo;
                  datos_articulo.articulo = item.nombre;
                  datos_articulo.id_articulo = item._id;

                }
                datos_articulo.precio_promedio_venta = datos_articulo.precio_promedio_venta.toFixed(2);
                this.items.push(datos_articulo);
              });
            } else {
              this.items = [];
            }
            window.dialogLoader.hide();
          })
          .catch(error => {
            console.log(error)
            window.dialogLoader.hide();
          })
      }
    },
    calcular_fecha: function(fecha) {
      this.model.fecha_de_venta = moment(String(fecha)).format("YYYY-MM-DD");
      this.model.fecha_a_venta = moment().format("YYYY-MM-DD");

      this.model.tipo = 1;
      this.calcular();
    },
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            numero = 0;
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    get_existencia: async function() {

      await window.funciones_lotes.consultaExistenciaArticulos().then(result => {
        if (result.length > 0) {
          if (this.model.id_sucursal._id == "0") {
            this.existencias = result;
          } else {
            this.existencias = result.filter(x => x.id_sucursal == this.model.id_sucursal._id);
          }
        }
        console.log("se asigno la existencia");

      }).catch(err => {
        console.log("error", err);
        this.existencia = [];
      });
    },
    get_articulos: async function() {
      let data = {
        "selector": {
          "type": "articulos",
          "estatus": "Activo"
        },
        "limit": 50000        
      };

      //Se pedía la sucursal pero pues no se usa para calcular el maximo y minimo pues esos son generales, cuando hacen un pedido lo hacen para todas las sucursales 
      if (this.model.id_sucursal._id != "0") {
        data["selector"][`stock.${this.model.id_sucursal._id}`] = {
          "$ne": null
        };
      }

      await window.axios.post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos = response.data.docs;
          } else
            this.articulos = [];

        })
        .catch(error => {
          console.log(error)
        });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },
    cerrar: function() {
      this.items = [];
      this.model = {
        fecha_de: "",
        fecha_a: "",
        periodo: "1",
        id_articulo: "",
        tipo: 1,
        fecha_de_venta: "",
        fecha_a_venta: "",
        plazo: 0,
        id_sucursal: ""
      };
      this.total_venta = 0;
      this.promedio_venta = 0;
      this.precio_promedio_venta = 0;
      this.props.modal = false;
      this.minimo = 0;
      this.reorden = 0;
      this.maximo = 0;
      this.cantidad_pedir = 0;
      this.consumo_minimo = 0;
      this.consumo_medio = 0;
      this.consumo_maximo = 0;
      this.existencia = 0;
      this.articulo = {};
      this.articulos = [];
      this.existencias = [];
    },
    guardar: function() {
      let data_save = [];
      window.dialogLoader.show('Espere un momento por favor..');      
      this.items.forEach((item) => {
        let arti = this.articulos.find(x => x._id == item.id_articulo);
        if (arti) {          
          if (arti.stock == undefined) {
            arti.stock = {};
          }
          if (this.model.id_sucursal._id != "0") {
            arti['stock'][this.model.id_sucursal._id] = {
              "minimo": item.minimo,
              "maximo": item.maximo,
              "reorden": item.reorden
            };
          } else {
            this.props.sucursales.forEach((suc) => {
              if (suc._id != "0") {
                arti['stock'][suc._id] = {
                  "minimo": item.minimo,
                  "maximo": item.maximo,
                  "reorden": item.reorden
                };
              }
            });
          }
         /*   if (this.model.id_sucursal._id != "0") {
            arti['stock'][this.model.id_sucursal._id] = {
              "minimo": Math.floor(Math.random() * 100) + 1,
              "maximo": Math.floor(Math.random() * 500) + 300,
              "reorden": Math.floor(Math.random() * 300) + 100
            };
          } else {
            this.props.sucursales.forEach((suc) => {
              if (suc._id != "0") {
                arti['stock'][suc._id] = {
                  "minimo": Math.floor(Math.random() * 100) + 1,
                  "maximo": Math.floor(Math.random() * 500) + 300,
                  "reorden": Math.floor(Math.random() * 300) + 100
                };
              }
            });
          } */
          data_save.push(arti);
        }
      });

      if (data_save.length > 0) {
        let url_bulk = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_bulk_docs";

        window.axios.post(url_bulk, {
            "docs": data_save
          })
          .then(response => {
            let flag = response.data.filter(re => re.ok != true);

            if(flag.length == 0) {
              this.$swal({
                    type: "success",
                    title: "¡Operación Exitosa!",
                    text: "Los datos se guardaron correctamente.",
                    footer: ""
                });              
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación Incompleta!",
                text: `${flag.length} Artículos no se actualizaron correctamente`,
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al aplicar la operación.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      } else {
        this.$swal({
          type: "error",
          title: "¡Operación no Permitida!",
          text: "No se enviarón elementos a guardar.",
          footer: ""
        });
        window.dialogLoader.hide();
      }
    }
  }
}
</script>
